import React, { useEffect, useState } from 'react'

// Services
import LocalStorageService from '../../../services/LocalStorageService'
import OrganisationTagService from '../../../services/OrganisationTagService'
import UserService from '../../../services/UserService'

// Appt Components
import DateInput from '../../../components/simple/input/DateInput'
import MaskedInput from '../../../components/simple/input/MaskedInput'
import Select from '../../../components/simple/input/Select'
import TextInput from '../../../components/simple/input/TextInput'

import constants from '../../../utils/constants'

const ValueBox = (props) => {
  const [valueField, setValueField] = useState()
  const [autoCompleteValues, setAutoCompleteValues] = useState([])
  const [timeValue, setTimeValue] = useState()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const terms = LocalStorageService.get('terms')

  var autoCompleteField
  // const autoCompleteField = (
  //   <Select
  //     {...props}
  //     labelKey='label'
  //     options={autoCompleteValues}
  //     valueKey={{ key: 'value', reduce: true }}
  //   />)

  const booleanField = (
    <Select
      {...props}
      labelKey='label'
      options={[
        {
          label: 'True',
          value: 'true'
        },
        {
          label: 'False',
          value: 'false'
        }
      ]}
      valueKey={{ key: 'value', reduce: true }}
    />)

  const dateField = (
    <DateInput
      format='dd/mm/yyyy'
      name={props.name}
    />)

  const integerField = (
    <TextInput
      name={props.name}
      type='number'
    />)

  const listField = (
    <Select
      {...props}
      labelKey='label'
      //   onChange={({ value: nextValue }) => {
      //     props.handleFieldChanged(props.name, nextValue)
      //   }}
      options={constants[props.list]}
      valueKey={{ key: 'value' }}
    />)

  const textField = (
    <TextInput
      name={props.name}
      type='text'
    />)

  const timeField = (
    <MaskedInput
      dropProps={{ background: { color: 'white' }, plain: true }}
      mask={[
        {
          length: [1, 2],
          options: Array.from({ length: 24 }, (v, k) => k + 1),
          placeholder: 'hh'
        },
        { fixed: ':' },
        {
          length: 2,
          options: ['00', '15', '30', '45'],
          regexp: /^[0-5][0-9]$|^[0-9]$/,
          placeholder: 'mm'
        }
      ]}
      name={props.name}
      // value={timeValue}
      // onChange={event => setTimeValue(event.target.value)}
    />)

  // Set Value field based on field datatype
  const setValue = async () => {
    switch (props.type) {
      case 'autocomplete':
        setValueField(autoCompleteField)
        break
      case 'boolean':
        setValueField(booleanField)
        break
      case 'date':
        setValueField(dateField)
        break
      case 'integer':
        setValueField(integerField)
        break
      case 'list':
        setValueField(listField)
        break
      case 'time':
        setValueField(timeField)
        break
      default:
        setValueField(textField)
    }
  }

  useEffect(() => {
    let unmounted = false

    const getUsers = async (userType) => {
      const params = {
        fields: 'id,firstName,lastName,reference',
        limit: 1000,
        type: userType,
        where: {
          deleted: false
        }
      }

      const users = await UserService.getUsers(apiToken, params)

      const mappedUsers = users.data.map(item => (
        {
          label: item.firstName + ' ' + item.lastName,
          value: String(item.id)
        }
      ))
      setAutoCompleteValues(mappedUsers)
    }

    const getAcademicYears = async () => {
      const academicYears = await OrganisationTagService.getAcademicYears(apiToken, activeOrg)

      const mappedAcademicYears = academicYears.map(item => (
        {
          label: item,
          value: item
        }
      ))

      setAutoCompleteValues(mappedAcademicYears)
    }

    const getDeescalationTechniques = async () => {
      const params = {
        fields: 'value',
        limit: 1,
        orgId: activeOrg.id
      }

      const where = {
        organisation: activeOrg.id,
        type: 'besmart',
        key: 'deescalationTechniques'
      }

      const techniques = await OrganisationTagService.get(apiToken, params, where)

      if (techniques) {
        const deescalationTechniques = JSON.parse(techniques[0].value)

        const mappedDeescalationTechniques = deescalationTechniques.map(item => (
          {
            label: item.technique,
            value: item.technique
          }
        ))

        setAutoCompleteValues(mappedDeescalationTechniques)
        console.log(mappedDeescalationTechniques)
      }
    }

    const getPhysicalTechniques = async () => {
      const params = {
        fields: 'value',
        limit: 1,
        orgId: activeOrg.id
      }

      const where = {
        organisation: activeOrg.id,
        type: 'besmart',
        key: 'physicalTechniques'
      }

      const techniques = await OrganisationTagService.get(apiToken, params, where)

      if (techniques) {
        const physicalTechniques = JSON.parse(techniques[0].value)

        const mappedPhysicalTechniques = physicalTechniques.map(item => (
          {
            label: item.technique,
            value: item.technique
          }
        ))

        setAutoCompleteValues(mappedPhysicalTechniques)
        console.log(mappedPhysicalTechniques)
      }
    }

    switch (props.fieldType) {
      case 'studentName':
        getUsers('student')
        break
      case 'studentsInvolved':
        getUsers('student')
        break
      case 'serviceuser_academicyear':
        getAcademicYears()
        break
      case 'staffName':
      case 'completedBy':
      case 'staffInvolved':
        getUsers('user')
        break
      case 'deescalationTechniques':
        getDeescalationTechniques()
        break
      case 'physicalTechnique':
        getPhysicalTechniques()
        break

      case 'injurySufferedByChild':
      case 'injurySufferedByStaff':
      case 'injurySufferedByOthers':
        setAutoCompleteValues([
          { label: 'True', value: 'true' }
        ])
        break

      case 'injurySufferedBy':
        let serviceUserTerm = terms.serviceUserTerm ? terms.serviceUserTerm : 'Student'
        setAutoCompleteValues([
          { label: 'Staff', value: 'staff' },
          { label: 'Others', value: 'other' },
          { label: serviceUserTerm, value: serviceUserTerm.toLowerCase() }
        ])
        break


      case 'medicalInterventionRequired':
        setAutoCompleteValues([
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' }
        ])
        break
    }

    setValue()

    return () => { unmounted = true }
  }, [props.type, props.list, props.fieldType])

  useEffect(() => {
    var theseValues = autoCompleteValues
    // sort alphabetically
    theseValues.sort(function (a, b) {
      return a.label.localeCompare(b.label)
    })

    autoCompleteField = (
      <Select
        {...props}
        labelKey='label'
        options={theseValues}
        valueKey={{ key: 'value', reduce: true }}
      />)

    setValue()
  }, [autoCompleteValues])
  return (
    <>
      {valueField}
    </>
    // <>
    //   <Select
    //     {...props}
    //     name='reportSearch'
    //     labelKey='label'
    //     onChange={({ value: nextValue }) => {
    //       props.handleFieldChanged(props.name, nextValue)
    //     }}
    //     options={constants.reportSearch}
    //     valueKey={{ key: 'value' }}
    //   />
    // </>
  )
}

export default ValueBox

import React, { useEffect, useState } from 'react';

// Spinner
import PulseLoader from 'react-spinners/PulseLoader';

import styled from 'styled-components';

// Appt Components
import Box from '../../components/simple/box/Box';
import Button from '../../components/simple/button/Button';
import DateTime from '../../components/compound/dateTime/DateTime';
import Form from '../../components/simple/form/Form.js';
import FormField from '../../components/simple/formField/FormField.js';
import Divider from '../../components/simple/divider/Divider';
import H2 from '../../components/simple/heading/H2.js';
import NotificationLayer from '../../components/compound/notification/NotificationLayer';
import OptionButtons from '../../components/compound/optionButtons/OptionButtons';
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid';
import Select from '../../components/simple/input/Select';
import TextArea from '../../components/simple/input/TextArea';
import TextInput from '../../components/simple/input/TextInput';
import Text from '../../components/simple/text/Text';
import RadioButtonGroup from '../../components/simple/input/RadioButtonGroup';
import DataTable from '../../components/simple/dataTable/DataTable.js';
import LocalStorageService from '../../services/LocalStorageService';

import { capitalize } from '../../utils/text';
import constants from '../../utils/constants';

import FontAwesome from '../../components/compound/fontAwesome/FontAwesome';
import { isElement } from 'react-dom/test-utils';

const questionTextStyle = {
  'margin-right': '12px',
  'margin-top': '6px',
  'margin-bottom': '0px',
  'margin-left': '0px',
  'font-size': '14px',
  'line-height': '20px',
  'font-weight': '700',
};

function IncidentReport(props) {
  const [loading, setLoading] = useState(false);
  const [localIncidentValues, setLocalIncidentValues] = useState(
    props.incidentValues,
  );
  const [interventionAddButton, setInterventionAddButton] = useState(false);
  const [interventionColumns, setInterventionDataColumns] = useState();
  const [mappedInterventions, setMappedInterventions] = useState([]);
  const [questions, setQuestions] = useState(props.questions);
  const terms = LocalStorageService.get('terms');
  const [editingDisabled] = useState(props.formId);

  const [studentValues, setStudentValues] = useState(props.studentValues);
  const [userValues, setUserValues] = useState(props.userValues);

  const [reportByValues, setReportByValues] = useState(props.userValues);
  const [staffInvolvedValues, setStaffInvolvedValues] = useState(props.userValues);
  const [otherServiceUserValues, setOtherServiceUserValues] = useState(props.studentValues);

  useEffect(() => {
    setStudentValues(props.studentValues);
    setOtherServiceUserValues(props.studentValues);
  }, [props.studentValues]);

  useEffect(() => {
    setUserValues(props.userValues);
    setReportByValues(props.userValues);
    setStaffInvolvedValues(props.userValues);
  }, [props.userValues]);

  // Don't allow change of tab
  const [stayOnTab, setStayOnTab] = useState(false);
  const removeIntervention = data => {
    const newInterventions = props.interventions.filter(
      item => item.interventionKey !== data.interventionKey,
    );

    props.setIntervention(newInterventions);
  };
  const addIntervention = () => {
    const intervention = {
      interventionKey: props.interventions.length,
      injurySufferedBy: localIncidentValues.injurySufferedBy,
      injury: localIncidentValues.injury,
      treatment: localIncidentValues.treatment,
      injurySufferedName: localIncidentValues.injurySufferedName,
      injuryTreatedBy: localIncidentValues.injuryTreatedBy,
    };
    props.setIntervention(interventions => [...interventions, intervention]);

    setLocalIncidentValues(localIncidentValues => ({
      ...localIncidentValues,
      injurySufferedBy: '',
      injury: '',
      treatment: '',
      injurySufferedName: '',
      injuryTreatedBy: ''
    }));
    setInterventionAddButton(false);
  };

  useEffect(() => {
    setInterventionDataColumns([
      {
        property: 'injurySufferedBy',
        header: (
          <Text pad="none" margin="none">
            Injury Suffered by
          </Text>
        ),
      },
      {
        property: 'injury',
        header: <Text>Injury</Text>,
      },
      {
        property: 'treatment',
        header: <Text>Treatment</Text>,
      },
      {
        property: 'injurySufferedName',
        header: <Text>Name</Text>,
      },
      {
        property: 'injuryTreatedBy',
        header: <Text>Treated By</Text>,
      },
      {
        property: 'actions',
        render: datum => (
          <Box direction="row">
            <Button
              label={
                <Text>
                  <FontAwesome icon={['fal', 'minus-circle']} />
                </Text>
              }
              margin={{ horizontal: 'xsmall' }}
              style={{ background: '#333' }}
              onClick={() => removeIntervention(datum)}
              target="_self"
            />
          </Box>
        ),
      },
    ]);

    const mappedInterventions = props.interventions.map(
      (intervention, index) => ({
        interventionKey: index,
        injurySufferedBy: intervention.injurySufferedBy,
        injury: intervention.injury,
        treatment: intervention.treatment,
        injurySufferedName: intervention.injurySufferedName,
        injuryTreatedBy: intervention.injuryTreatedBy,
      }),
    );

    setMappedInterventions(mappedInterventions);
  }, [props.interventions]);

  const TallTextArea = styled(TextArea)`
    min-height: 100%;
  `;

  const previousPage = () => {
    props.previousPage(localIncidentValues);
  };

  const nextPage = () => {
    // if (!localIncidentValues.studentName.name) {
    // setStayOnTab(true)
    // } else {
    props.nextPage(localIncidentValues);
    // }
  };

  const submitForm = () => {
    // if (!localIncidentValues.studentName.name) {
    //   setStayOnTab(true)
    // } else {
    props.submitForm(localIncidentValues, 'Draft');
    // }
  };

  const setDateTime = (date, time) => {
    setLocalIncidentValues(localIncidentValues => ({
      ...localIncidentValues,
      dateOfIncident: date,
      timeOfIncident: time,
    }));
  };

  // Need to include this as it takes time for
  // props.incidentValues to get populated
  useEffect(() => {
    var propsToUpdate = props.incidentValues;

    console.log('localIncidentValues', localIncidentValues);

    // don't allow the student name to be replaced by the draft
    if (propsToUpdate.studentName && !propsToUpdate.studentName.id) {
      propsToUpdate.studentName = localIncidentValues.studentName;
    }
    setLocalIncidentValues(propsToUpdate);
  }, [props.incidentValues]);



  const getUserFromId = (id, type = 'serviceuser') => {
    if (!id || id === '' || id?.length <= 0) {
      return '';
    }
    var toSearch = String(id).split(',');
    var toReturn = [];
    for (const id of toSearch) {
      var thisString = '-';
      var match = false;
      if (type === 'serviceuser') {
        match = studentValues.find(u => {
          return parseInt(u.id) === parseInt(id);
        });
        if (match) {
          thisString = match.name || '';
        }
      } else {
        match = userValues.find(u => {
          return parseInt(u.id) === parseInt(id);
        });
        if (match) {
          thisString = match.name || '';
        }
      }
      toReturn.push(thisString);
    }

    return toReturn.join(',');
  };

  useEffect(() => {
    if (localIncidentValues.studentName.name) {
      props.getContacts(localIncidentValues.studentName.id);

      props.getIncidentPlans(localIncidentValues.studentName.id);
    }
  }, [localIncidentValues.studentName]);

  var sufferedByOptions = [];
  for (var thisField of constants.injurySufferedBy) {
    if (thisField?.includes('[Student]')) {
      thisField = thisField.replace(
        '[Student]',
        capitalize(terms?.serviceUser || 'Service User'),
      );
    }
    sufferedByOptions.push(thisField);
  }

  return (
    <Box
      gridArea="main"
      background="white"
      direction="column"
      gap="small"
      round="small"
    >
      <Box
        gap="small"
        margin={{ horizontal: 'small' }}
        pad="small"
        round="small"
      >
        <Box fill direction="row" gap="small">
          <H2 margin={{ vertical: 'none' }}>Incident Report</H2>
          <Button
            label={
              <FontAwesome color="brand" icon={['fas', 'question-circle']} />
            }
            plain
            tip="This is the first page of the Incident Report"
          />
        </Box>
        <Divider color="primary" margin={{ top: 'none', bottom: 'small' }} />
        {props.studentValues && questions.length ? (
          <>
            {stayOnTab && (
              <NotificationLayer
                button1Text="OK"
                button1Click={() => {
                  setStayOnTab(false);
                }}
              >
                Please complete '{questions[0].questionText}' before continuing
              </NotificationLayer>
            )}

            <Form
              //   validate='blur'
              onChange={nextValue => {
                // Enable Intervention Add button if all fields have data
                setInterventionAddButton(
                  nextValue.injurySufferedBy !== '' &&
                    nextValue.injury !== '' &&
                    nextValue.treatment !== '' &&
                    nextValue.injurySufferedName !== '' &&
                    nextValue.injuryTreatedBy !== ''
                );

                setLocalIncidentValues(nextValue);
              }}
              onSubmit={e => {
                if (e.nativeEvent.submitter.id === 'next') {
                  return nextPage();
                } else if (e.nativeEvent.submitter.id === 'back') {
                  return previousPage();
                }
                submitForm();
              }}
              value={localIncidentValues}
              validate="blur"
            >
              <ResponsiveGrid
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['25%', '25%', '25%', '25%'],
                  large: ['25%', '25%', '25%', '25%'],
                  xlarge: ['25%', '25%', '25%', '25%'],
                }}
                gap="small"
              >
                {/* Student Name */}
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                  required
                >
                  {!editingDisabled && (
                    <Select
                      // id='student'
                      disabled={props.userId !== 'new'}
                      name={questions[0].key}
                      labelKey="name"
                      placeholder={
                        'Select ' +
                        (props?.terms?.serviceUser || 'service user')
                      }
                      options={studentValues}
                      onSearch={text => {
                        // The line below escapes regular expression special characters:
                        // [ \ ^ $ . | ? * + ( )
                        const escapedText = text.replace(
                          /[-\\^$*+?.()|[\]{}]/g,
                          '\\$&',
                        );
                        // Create the regular expression with modified value which
                        // handles escaping special characters. Without escaping special
                        // characters, errors will appear in the console
                        const exp = new RegExp(escapedText, 'i');

                        setStudentValues(
                          props.studentValues.filter(o => exp.test(o.name)),
                        );
                      }}
                      valueKey={{ key: 'id', reduce: false }}
                      // valueKey='id'
                    />
                  )}

                  {editingDisabled && (
                    <Text>{`${
                      localIncidentValues?.studentName?.firstName || ''
                    } ${
                      localIncidentValues?.studentName?.lastName || ''
                    }`}</Text>
                  )}
                </FormField>

                <FormField
                  label={questions[1].questionText}
                  name={questions[1].key}
                  required={true}
                >
                  {!editingDisabled && (
                    <DateTime
                      date={localIncidentValues.dateOfIncident}
                      time={localIncidentValues.timeOfIncident}
                      // name={questions[1].key}
                      setDateTime={setDateTime}
                    />
                  )}
                  {editingDisabled && (
                    <Text>{`${props.incidentValues?.dateOfIncident || ''} ${
                      localIncidentValues?.timeOfIncident || ''
                    }`}</Text>
                  )}
                </FormField>

                {/* Location */}
                <FormField
                  label={questions[3].questionText}
                  name={questions[3].key}
                  required
                >
                  {!editingDisabled && (
                    <TextInput
                      name={questions[3].key}
                      placeholder="Location"
                      type="text"
                    />
                  )}
                  {editingDisabled && (
                    <Text>
                      {props.incidentValues?.[questions[3].key] || 'n/a'}
                    </Text>
                  )}
                </FormField>
              </ResponsiveGrid>

              <ResponsiveGrid
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['25%', '25%', '25%', '25%'],
                  large: ['25%', '25%', '25%', '25%'],
                  xlarge: ['25%', '25%', '25%', '25%'],
                }}
                gap="small"
              >
                {/* Completed By */}
                <FormField
                  label={questions[4].questionText}
                  name={questions[4].key}
                  required
                >
                  {!editingDisabled && (
                    <Select
                      // id='student'
                      name={questions[4].key}
                      labelKey="name"
                      placeholder="Completed by"
                      // onChange={({ option }) => changeOrganisation(option)}
                      clear={{ label: 'Clear Selection' }}
                      onSearch={text => {
                        // The line below escapes regular expression special characters:
                        // [ \ ^ $ . | ? * + ( )
                        const escapedText = text.replace(
                          /[-\\^$*+?.()|[\]{}]/g,
                          '\\$&',
                        );
                        // Create the regular expression with modified value which
                        // handles escaping special characters. Without escaping special
                        // characters, errors will appear in the console
                        const exp = new RegExp(escapedText, 'i');

                        setReportByValues(
                          props.userValues.filter(o => exp.test(o.name)),
                        );
                      }}
                      options={reportByValues}
                      valueKey={{ key: 'id', reduce: true }}
                    />
                  )}

                  {editingDisabled && (
                    <Text>
                      {getUserFromId(
                        props.incidentValues?.[questions[4].key],
                        'staff',
                      )}
                    </Text>
                  )}
                </FormField>

                {/* Staff Involved */}
                <FormField
                  label={questions[5].questionText}
                  name={questions[5].key}
                  required
                >
                  {!editingDisabled && (
                    <Select
                      // id='student'
                      name={questions[5].key}
                      labelKey="name"
                      multiple
                      placeholder="Staff Involved"
                      // onChange={({ option }) => changeOrganisation(option)}
                      options={staffInvolvedValues}
                      clear={{ label: 'Clear Selection' }}
                      onSearch={text => {
                        // The line below escapes regular expression special characters:
                        // [ \ ^ $ . | ? * + ( )
                        const escapedText = text.replace(
                          /[-\\^$*+?.()|[\]{}]/g,
                          '\\$&',
                        );
                        // Create the regular expression with modified value which
                        // handles escaping special characters. Without escaping special
                        // characters, errors will appear in the console
                        const exp = new RegExp(escapedText, 'i');

                        setStaffInvolvedValues(
                          props.userValues.filter(o => exp.test(o.name) || localIncidentValues?.staffInvolved?.includes(o.id))
                        );
                        
                        // setStaffInvolvedValues(
                          // props.userValues.filter(o => exp.test(o.name)),
                        // );
                      }}
                      valueKey={{ key: 'id', reduce: true }}
                    />
                  )}
                  {editingDisabled && (
                    <Text>
                      {getUserFromId(
                        props.incidentValues?.[questions[5].key],
                        'staff',
                      )}
                    </Text>
                  )}
                </FormField>

                {/* Students Involved */}
                <FormField
                  label={questions[6].questionText}
                  name={questions[6].key}
                  required
                >
                  {!editingDisabled && (
                    <Select
                      // id='student'
                      name={questions[6].key}
                      labelKey="name"
                      multiple
                      placeholder={
                        (props?.terms?.serviceUserPlural || 'Service users') +
                        ' involved'
                      }
                      clear={{ label: 'Clear Selection' }}
                      onSearch={text => {
                        // The line below escapes regular expression special characters:
                        // [ \ ^ $ . | ? * + ( )
                        const escapedText = text.replace(
                          /[-\\^$*+?.()|[\]{}]/g,
                          '\\$&',
                        );
                        // Create the regular expression with modified value which
                        // handles escaping special characters. Without escaping special
                        // characters, errors will appear in the console
                        const exp = new RegExp(escapedText, 'i');

                        setOtherServiceUserValues(
                          props.studentValues.filter(o => exp.test(o.name) || localIncidentValues?.studentsInvolved?.includes(o.id)),
                        );
                      }}
                      // onChange={({ option }) => changeOrganisation(option)}
                      options={otherServiceUserValues}
                      valueKey={{ key: 'id', reduce: true }}
                    />
                  )}
                  {editingDisabled && (
                    <Text>
                      {getUserFromId(
                        props.incidentValues?.[questions[6].key],
                        'serviceuser',
                      )}
                    </Text>
                  )}
                </FormField>

                {/* Witnesses */}
                <FormField
                  label={questions[7].questionText}
                  name={questions[7].key}
                >
                  {!editingDisabled && (
                    <TextInput
                      name={questions[7].key}
                      placeholder="Witnesses"
                      type="text"
                    />
                  )}
                  {editingDisabled && (
                    <Text>
                      {props.incidentValues?.[questions[7].key] || 'n/a'}
                    </Text>
                  )}
                </FormField>

              </ResponsiveGrid>

              <Box>
              <ResponsiveGrid
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['25%', '25%', '25%', '25%'],
                  large: ['25%', '25%', '25%', '25%'],
                  xlarge: ['25%', '25%', '25%', '25%'],
                }}
                gap="small"
              > 


                <Box direction="column" gap="small">
                        {/* anybody injured medical intervention required ? */}
                  <FormField
                    label={
                      <Box height="40px"  style={{ 'margin-bottom': '20px' }}>{questions[27].questionText}</Box>
                    }
                    name={questions[27].key}
                    required
                  >
                    {!editingDisabled && (
                      <OptionButtons
                        id={questions[27].key}
                        name={questions[27].key}
                        value={
                          localIncidentValues.medicalInterventionRequired ||
                          'No'
                        }
                      />
                    )}
                    {editingDisabled && (
                      <Text>
                        {props.incidentValues?.[questions[27].key] === 'Yes'
                          ? 'Yes'
                          : 'No'}
                      </Text>
                    )}
                  </FormField>
                </Box>


              <Box direction="column" gap="small">
                    {/* physical intervention used ? */}
                    <FormField
                      label={
                        <>
                          <div style={{ 'margin-bottom': '20px' }}>
                            <Text style={questionTextStyle}>
                              {questions[28].questionText}
                            </Text>{' '}
                            <Button
                              label={
                                <Text>
                                  <FontAwesome
                                    color="brand"
                                    icon={['fas', 'question-circle']}
                                  />
                                </Text>
                              }
                              plain
                              tip={{
                                content: (
                                  <Box
                                    background={{ color: 'white' }}
                                    border={{ color: 'brand', size: 'small' }}
                                    direction="column"
                                    pad="small"
                                  >
                                    <Text>
                                      Minimal resistance eg a person is going with
                                      you willingly
                                    </Text>
                                  </Box>
                                ),
                              }}
                            />
                          </div>
                        </>
                      }
                      name={questions[28].key}
                      required
                    >
                      {!editingDisabled && (
                        <OptionButtons
                          id={questions[28].key}
                          name={questions[28].key}
                          value={
                            localIncidentValues.physicalInterventionUsed || 'No'
                          }
                        />
                      )}
                      {editingDisabled && (
                        <Text>
                          {props.incidentValues?.[questions[28].key] || ''}
                        </Text>
                      )}
                    </FormField>

                  </Box>

                  <Box direction="column" gap="small">
                    

                    {/* restricted physical intervention used ? */}
                    <FormField
                      label={
                        <>
                          <div style={{ 'margin-bottom': '20px' }}>
                            <Text style={questionTextStyle}>
                              {questions[26].questionText}
                            </Text>{' '}
                            <Button
                              label={
                                <Text>
                                  <FontAwesome
                                    color="brand"
                                    icon={['fas', 'question-circle']}
                                  />
                                </Text>
                              }
                              plain
                              tip={{
                                content: (
                                  <Box
                                    background={{ color: 'white' }}
                                    border={{ color: 'brand', size: 'small' }}
                                    direction="column"
                                    pad="small"
                                  >
                                    <Text>
                                      If an individual is resisting and force is
                                      used it becomes restrictive physical
                                      intervention.
                                    </Text>
                                  </Box>
                                ),
                              }}
                            />
                          </div>
                        </>
                      }
                      name={questions[26].key}
                      required
                    >
                      {!editingDisabled && (
                        <OptionButtons
                          id={questions[26].key}
                          name={questions[26].key}
                          value={
                            localIncidentValues.restrictedPhysicalInterventionUsed ||
                            'No'
                          }
                        />
                      )}
                      {editingDisabled && (
                        <Text>
                          {props.incidentValues?.[questions[26].key] || ''}
                        </Text>
                      )}
                    </FormField>
                    
                  </Box>

                </ResponsiveGrid>
                </Box>

        
                {(localIncidentValues.restrictedPhysicalInterventionUsed === 'Yes' || localIncidentValues.physicalInterventionUsed === 'Yes') && <ResponsiveGrid
                rows={['auto']}
                columns={{
                  small: ['auto'],
                  medium: ['25%', '25%', '25%', '25%'],
                  large: ['25%', '25%', '25%', '25%'],
                  xlarge: ['25%', '25%', '25%', '25%'],
                }}
                gap="small"
              >

                <Box direction="column">
                  {/* Hurting Self */}
                  <FormField
                    label={<Box height="40px">{questions[8].questionText}</Box>}
                    name={questions[8].key}
                  >
                    {!editingDisabled && (
                      <OptionButtons
                        id={questions[8].key}
                        name={questions[8].key}
                        value={localIncidentValues.hurtingSelf || 'No'}
                      />
                    )}
                    {editingDisabled && (
                      <Text>
                        {props.incidentValues?.[questions[8].key]
                          ? 'Yes'
                          : 'No'}
                      </Text>
                    )}
                  </FormField>

                    {/* Other Behaviour we are trying to support */}
                    <FormField
                      label={
                        <Box height="40px">{questions[12].questionText}</Box>
                      }
                      name={questions[12].key}
                    >
                      {!editingDisabled && (
                        <OptionButtons
                          id={questions[12].key}
                          name={questions[12].key}
                          value={localIncidentValues.behaviour || 'No'}
                        />
                      )}
                      {editingDisabled && (
                        <Text>
                          {props.incidentValues?.[questions[12].key]
                            ? 'Yes'
                            : 'No'}
                        </Text>
                      )}
                    </FormField>

                </Box>

                <Box direction="column">

                  {/* Hurting Others */}
                  <FormField
                    label={<Box height="40px">{questions[9].questionText}</Box>}
                    name={questions[9].key}
                  >
                    {!editingDisabled && (
                      <OptionButtons
                        id={questions[9].key}
                        name={questions[9].key}
                        value={localIncidentValues.hurtingOthers || 'No'}
                      />
                    )}
                    {editingDisabled && (
                      <Text>
                        {props.incidentValues?.[questions[9].key]
                          ? 'Yes'
                          : 'No'}
                      </Text>
                    )}
                  </FormField>

                </Box>

                <Box direction="column">

                  {/* Damage to property */}
                  <FormField
                    label={
                      <Box height="40px">{questions[11].questionText}</Box>
                    }
                    name={questions[11].key}
                  >
                    {!editingDisabled && (
                      <OptionButtons
                        id={questions[11].key}
                        name={questions[11].key}
                        value={localIncidentValues.propertyDamage || 'No'}
                      />
                    )}
                    {editingDisabled && (
                      <Text>
                        {props.incidentValues?.[questions[11].key]
                          ? 'Yes'
                          : 'No'}
                      </Text>
                    )}
                  </FormField>

                </Box>

                <Box direction="column">
                  
                  {/* Committing an offence */}
                  <FormField
                    label={
                      <Box height="40px">{questions[10].questionText}</Box>
                    }
                    name={questions[10].key}
                  >
                    {!editingDisabled && (
                      <OptionButtons
                        id={questions[10].key}
                        name={questions[10].key}
                        value={localIncidentValues.commitOffence || 'No'}
                      />
                    )}
                    {editingDisabled && (
                      <Text>
                        {props.incidentValues?.[questions[10].key]
                          ? 'Yes'
                          : 'No'}
                      </Text>
                    )}
                  </FormField>

                </Box>
              </ResponsiveGrid>}

              {(localIncidentValues.restrictedPhysicalInterventionUsed === 'Yes' || localIncidentValues.physicalInterventionUsed === 'Yes') && <Box>
                <ResponsiveGrid
                  rows={['auto']}
                  columns={{
                    small: ['auto'],
                    medium: ['25%', '25%', '25%', '25%'],
                    large: ['25%', '25%', '25%', '25%'],
                    xlarge: ['25%', '25%', '25%', '25%'],
                  }}
                  gap="small"
                >
                  <Box direction="column" gap="small">

                    {/* dynamicRisk? */}
                    <FormField
                      label={<Box height="40px" style={{ 'margin-bottom': '20px' }}>{questions[17].questionText}</Box>}
                      name={questions[17].key}
                    >
                      {!editingDisabled && (
                        <OptionButtons
                          id={questions[17].key}
                          name={questions[17].key}
                          value={localIncidentValues.dynamicRisk || 'No'}
                        />
                      )}
                      {editingDisabled && (
                        <Text>
                          {props.incidentValues?.[questions[17].key]
                            ? 'Yes'
                            : 'No'}
                        </Text>
                      )}
                    </FormField>
                </Box>
                <Box direction="column" gap="small">

                      {/* followPlan */}
                      <FormField
                      label={
                        <Box height="40px" style={{ 'margin-bottom': '20px' }}>{questions[13].questionText}</Box>
                      }
                      name={questions[13].key}
                    >
                      {!editingDisabled && (
                        <OptionButtons
                          id={questions[13].key}
                          name={questions[13].key}
                          value={localIncidentValues.followPlanQuestion || 'No'}
                        />
                      )}
                      {editingDisabled && (
                        <Text>
                          {props.incidentValues?.[questions[13].key] || 'No'}
                        </Text>
                      )}
                    </FormField>
                  </Box>
                  <Box direction="column" gap="small">

                      {/* Was your action in the best interest of the service user? */}
                      <FormField
                        label={
                          <Box height="40px" style={{ 'margin-bottom': '20px' }}>{questions[15].questionText}</Box>
                        }
                        name={questions[15].key}
                      >
                        {!editingDisabled && (
                          <OptionButtons
                            id={questions[15].key}
                            name={questions[15].key}
                            value={localIncidentValues.bestInterestQuestion || 'No'}
                          />
                        )}
                        {editingDisabled && (
                          <Text>
                            {props.incidentValues?.[questions[15].key] || 'No'}
                          </Text>
                        )}
                      </FormField>
                      </Box>
                 
                  </ResponsiveGrid>
              </Box>}
              {(localIncidentValues.restrictedPhysicalInterventionUsed === 'Yes' || localIncidentValues.physicalInterventionUsed === 'Yes') && 
              <Box>
              <ResponsiveGrid
                  rows={['auto']}
                  columns={{
                    small: ['auto'],
                    medium: ['25%', '25%', '25%', '25%'],
                    large: ['25%', '25%', '25%', '25%'],
                    xlarge: ['25%', '25%', '25%', '25%'],
                  }}
                  gap="small"
                >
                

                <Box direction="column" gap="small">

                     {/* Dynamic risk information */}
                     {localIncidentValues.dynamicRisk === 'Yes' && (
                      <FormField
                        label={
                          <Box height="40px" style={{ 'margin-bottom': '20px' }}>{questions[18].questionText}</Box>
                        }
                        name={questions[18].key}
                      >
                        {!editingDisabled && (
                          <TextArea style={{ height: '44px' }} name={questions[18].key} />
                        )}
                        {editingDisabled && (
                          <Text>
                            {props.incidentValues?.[questions[18].key] || ''}
                          </Text>
                        )}
                      </FormField>
                    )}

                </Box>
                <Box direction="column" gap="small">
                  {(!localIncidentValues.followPlanQuestion ||
                      localIncidentValues.followPlanQuestion === 'No') && (
                      // followPlan - Please Elaborate
                      <FormField
                        label={
                          <Box height="40px" style={{ 'margin-bottom': '20px' }}>{questions[14].questionText}</Box>
                        }
                        name={questions[14].key}
                      >
                        {!editingDisabled && (
                          <TextArea
                            style={{ height: '44px' }}
                            name={questions[14].key}
                            fill
                          />
                        )}
                        {editingDisabled && (
                          <Text>
                            {props.incidentValues?.[questions[14].key] || ''}
                          </Text>
                        )}
                      </FormField>
                    )}
                </Box>
                <Box direction="column" gap="small">

                    {/* Why was this [not] in the best interest of the service user? */}
                    <FormField
                      label={`Why was this ${
                        localIncidentValues.bestInterestQuestion === 'No'
                          ? 'not'
                          : ''
                      }  in the best interest of the service user?`}
                      name={questions[16].key}
                    >
                      {!editingDisabled && <TextArea name={questions[16].key} />}
                      {editingDisabled && (
                        <Text>
                          {props.incidentValues?.[questions[16].key] || 'No'}
                        </Text>
                      )}
                    </FormField>

                    </Box>
              </ResponsiveGrid>

                {localIncidentValues.restrictedPhysicalInterventionUsed ===
                  'Yes' && (
                  <Box direction="row-responsive" gap="small">
                    {/* Breathing monitored */}
                    <FormField
                      label={
                        <Box height="40px">{questions[19].questionText}</Box>
                      }
                      name={questions[19].key}
                      required
                    >
                      {!editingDisabled && (
                        <RadioButtonGroup
                          direction="row"
                          name={questions[19].key}
                          // onChange={event =>
                          //   setActionButton(event.target.value)}
                          options={['Yes', 'No']}
                        >
                          {(option, { checked, hover }) => {
                            let background;
                            if (checked) background = 'brand';
                            else if (hover) background = 'light-4';
                            else background = 'light-2';
                            return (
                              <Box background={background} pad="small">
                                {option}
                              </Box>
                            );
                          }}
                        </RadioButtonGroup>
                      )}
                      {editingDisabled && (
                        <Text>
                          {props.incidentValues?.[questions[19].key]
                            ? 'Yes'
                            : 'No'}
                        </Text>
                      )}
                    </FormField>

                    {/* Duration */}
                    <FormField
                      label={
                        <Box height="40px">{questions[20].questionText}</Box>
                      }
                      name={questions[20].key}
                      required
                    >
                      {!editingDisabled && (
                        <TextInput name={questions[20].key} type="number" />
                      )}
                      {editingDisabled && (
                        <Text>
                          {props.incidentValues?.[questions[20].key] || ''}
                        </Text>
                      )}
                    </FormField>

                    {/* Number of staff */}
                    <FormField
                      label={
                        <Box height="40px">{questions[21].questionText}</Box>
                      }
                      name={questions[21].key}
                      required
                    >
                      {!editingDisabled && (
                        <TextInput name={questions[21].key} type="number" />
                      )}
                      {editingDisabled && (
                        <Text>
                          {props.incidentValues?.[questions[21].key] || ''}
                        </Text>
                      )}
                    </FormField>
                  </Box>
                )}

              </Box>}
              {localIncidentValues.medicalInterventionRequired === 'Yes' && (
                  <>
                    <Box direction="row-responsive" gap="medium">
                      <DataTable
                        columns={interventionColumns}
                        data={mappedInterventions}
                        fill="horizontal"
                        primaryKey={false}
                        size="large"
                      />
                    </Box>
                    <Box direction="row-responsive" gap="medium">
                      {/* Injury Suffered By */}
                      <FormField
                        label={questions[22].questionText}
                        name={questions[22].key}
                      >
                        {!editingDisabled && (
                          <Select
                            name={questions[22].key}
                            options={sufferedByOptions}
                          />
                        )}
                        {editingDisabled && (
                          <Text>
                            {props.incidentValues?.[questions[22].key] || ''}
                          </Text>
                        )}
                      </FormField>

                      <FormField
                        label={questions[23].questionText}
                        name={questions[23].key}
                      >
                        {!editingDisabled && (
                          <TextInput name={questions[23].key} type="text" />
                        )}
                        {editingDisabled && (
                          <Text>
                            {props.incidentValues?.[questions[23].key] || ''}
                          </Text>
                        )}
                      </FormField>

                      <FormField
                        label={questions[24].questionText}
                        name={questions[24].key}
                      >
                        {!editingDisabled && (
                          <TextInput name={questions[24].key} type="text" />
                        )}
                        {editingDisabled && (
                          <Text>
                            {props.incidentValues?.[questions[24].key] || ''}
                          </Text>
                        )}
                      </FormField>

                      <FormField
                        label={questions[29].questionText}
                        name={questions[29].key}
                      >
                        {!editingDisabled && (
                          <TextInput name={questions[29].key} type="text" />
                        )}
                        {editingDisabled && (
                          <Text>
                            {props.incidentValues?.[questions[29].key] || ''}
                          </Text>
                        )}
                      </FormField>

                      <FormField
                        label={questions[30].questionText}
                        name={questions[30].key}
                      >
                        {!editingDisabled && (
                          <TextInput name={questions[30].key} type="text" />
                        )}
                        {editingDisabled && (
                          <Text>
                            {props.incidentValues?.[questions[29].key] || ''}
                          </Text>
                        )}
                      </FormField>

                      {!editingDisabled && (
                        <Button
                          disabled={!interventionAddButton}
                          label={
                            <Text>
                              <FontAwesome icon={['fal', 'plus-circle']} />
                            </Text>
                          }
                          color="primary"
                          onClick={() => addIntervention()}
                          style={{ height: '35px' }}
                          alignSelf="end"
                          margin={{ bottom: 'small' }}
                        />
                      )}
                    </Box>
                  </>
                )}
              {(localIncidentValues.restrictedPhysicalInterventionUsed ===
                'Yes' ||
                localIncidentValues.medicalInterventionRequired === 'Yes') && (
                <FormField
                  label={questions[25].questionText}
                  name={questions[25].key}
                >
                  {!editingDisabled && <TextArea name={questions[25].key} />}
                  {editingDisabled && (
                    <Text>
                      {props.incidentValues?.[questions[25].key] || 'n/a'}
                    </Text>
                  )}
                </FormField>
              )}

              <Divider
                color="primary"
                margin={{ top: 'medium', bottom: 'none' }}
              />

              <Box
                direction="row"
                gap="small"
                justify="between"
                margin={{ top: 'medium' }}
              >
                <Button id="back" type="submit" label="< Back" secondary />
                {!editingDisabled && (
                  <Button
                    type="submit"
                    icon={<FontAwesome icon={['fal', 'save']} color="#fff" />}
                    label="Save for later"
                  />
                )}
                <Button id="next" type="submit" label="Next Page" primary />
              </Box>
            </Form>
          </>
        ) : (
          <Box align="center">
            <PulseLoader size={15} color="orange" loading={loading} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default IncidentReport;

export const constants = {
         gender: [
           {
             label: 'Male',
             value: 'male',
           },
           {
             label: 'Female',
             value: 'female',
           },
           {
             label: 'Other',
             value: 'other',
           },
         ],
         ethnicity: [
           {
             label: 'White',
             value: 'white',
           },
           {
             label: 'Mixed or Multiple ethnic groups',
             value: 'mixed',
           },
           {
             label: 'Asian or Asian British',
             value: 'asian',
           },
           {
             label: 'Black, African Caribbean or Black British',
             value: 'black',
           },
           {
             label: 'Other ethnic group',
             value: 'other',
           },
         ],
         yesno: [
           {
             label: 'Yes',
             value: 'Yes',
           },
           {
             label: 'No',
             value: 'No',
           },
         ],
         reportSearch: [
           {
             label: 'Behaviour - Low Level',
             value: 'lowLevelBehaviour',
             type: 'text',
           },
           {
             label: 'Behaviour - Medium Level',
             value: 'mediumLevelBehaviour',
             type: 'text',
           },
           {
             label: 'Behaviour - High Level',
             value: 'highLevelBehaviour',
             type: 'text',
           },
           {
             label: 'Behaviour - Recovery Level',
             value: 'recoveryBehaviour',
             type: 'text',
           },
           {
             label: 'Deescalation Techniques Used',
             value: 'deescalationTechniques',
             type: 'autocomplete', // list of deescalation techniques,
             operators: ['contains'],
           },
           {
             label: 'Physical Techniques Used',
             value: 'physicalTechnique',
             type: 'autocomplete', // list of deescalation techniques
             operators: ['contains'],
           },
           {
             label: 'Incident - Physical Intervention Used',
             value: 'physicalInterventionUsed',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Incident - Restrictive Physical Intervention Used',
             value: 'restrictedPhysicalInterventionUsed',
             type: 'list',
             list: 'yesno',
           },
           // {
           //   label: 'Breathing Monitored',
           //   value: 'breathingMonitored',
           //   type: 'boolean' // yes or no
           // },
           // {
           //   label: 'Duration of Physical Intervention',
           //   value: 'physicalInterventionDuration',
           //   type: 'integer'
           // },
           // TODO: this should work
          //  {
          //    label: 'Injury - Suffered By Child',
          //    value: 'injurySufferedByChild',
          //    type: 'autocomplete',
          //  },
          //  // TODO: this should work
          //  {
          //    label: 'Injury - Suffered By Staff',
          //    value: 'injurySufferedByStaff',
          //    type: 'autocomplete',
          //  },
          //  // TODO: this should work
          //  {
          //    label: 'Injury - Suffered By Others',
          //    value: 'injurySufferedByOthers',
          //    type: 'autocomplete',
          //  },
           {
             label: 'Injury - Suffered By',
             value: 'injurySufferedBy',
             type: 'autocomplete',
           },
           {
             label: 'Injury',
             value: 'medicalInterventionRequired',
             operators: ['is'],
             type: 'autocomplete',
           },
           {
             label: 'Injury - Type',
             value: 'injury',
             type: 'text',
           },
           {
             label: 'Injury - Treatment',
             value: 'treatment',
             type: 'text',
           },
           // {
           //   label: 'Incident - Reference',
           //   value: 'id',
           //   type: 'text'
           // },
           {
             label: 'Incident - Date',
             value: 'dateOfIncident',
             type: 'date',
           },
           {
             label: 'Incident - Time',
             value: 'timeOfIncident',
             type: 'time',
           },
           {
             label: 'Incident - Location',
             value: 'location',
             type: 'text',
           },
           {
             label: '[Student] - Primary',
             value: 'studentName',
             type: 'autocomplete',
           },
           {
             label: '[Student] - Other Involved',
             value: 'studentsInvolved',
             type: 'autocomplete',
             operators: ['contains'],
           },
           {
             label: '[Student] - Age',
             value: 'serviceuser_age',
             type: 'integer',
           },
           {
             label: '[Student] - Gender',
             value: 'serviceuser_gender',
             type: 'list',
             list: 'gender',
           },
           {
             label: '[Student] - Ethnicity',
             value: 'serviceuser_ethnicity',
             type: 'list',
             list: 'ethnicity',
           },
           {
             label: '[Student] - Year Group',
             value: 'serviceuser_academicyear',
             type: 'autocomplete',
           },
           {
             label: '[Student] - Student Group',
             value: 'serviceuser_usergroup',
             type: 'text',
           },
           {
             label: 'Staff - Primary',
             value: 'completedBy',
             type: 'autocomplete', // staff members
           },
           {
             label: 'Staff - Involved',
             value: 'staffInvolved',
             type: 'autocomplete', // staff members
             operators: ['contains'],
           },
           {
             label: 'Staff - Num Involved',
             value: 'numberStaffInvolved',
             type: 'integer',
           },
           {
             label: 'Prejudicial Behaviours - Bullying',
             value: 'Bullying',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Prejudicial Behaviours - Racist',
             value: 'Racist',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Prejudicial Behaviours - Sexist',
             value: 'Sexist',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Prejudicial Behaviours - Disability',
             value: 'Disability',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Prejudicial Behaviours - Homophobic',
             value: 'Homophobic',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Prejudicial Behaviours - Biphobic',
             value: 'Biphobic',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Prejudicial Behaviours - Use of derogatory language',
             value: 'UseOfDerogatoryLanguage',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Prejudicial Behaviours - Sexual harassment',
             value: 'Sexualharassment',
             type: 'list',
             list: 'yesno',
           },
           {
             label: `Prejudicial Behaviours - [Student] On [Student]`,
             value: 'StudentOnStudent',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Prejudicial Behaviours - Self-injurious Behaviour',
             value: 'SelfInjuriousBehaviour',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Prejudicial Behaviours - Suspension',
             value: 'Suspension',
             type: 'list',
             list: 'yesno',
           },
           {
             label: 'Prejudicial Behaviours - PermanentExclusion',
             value: 'PermanentExclusion',
             type: 'list',
             list: 'yesno',
           },
           // {
           //   label: 'Incident - Likely Triggers',
           //   value: 'likelyTriggers',
           //   type: 'text'
           // },
           // {
           //   label: 'Incident - Hurting Self',
           //   value: 'hurtingSelf',
           //   type: 'list',
           //   list: 'yesno'
           // },
           // {
           //   label: 'Incident - Hurting Others',
           //   value: 'hurtingOthers',
           //   type: 'list',
           //   list: 'yesno'
           // },
           // {
           //   label: 'Incident - Property Damage',
           //   value: 'propertyDamage',
           //   type: 'list',
           //   list: 'yesno'
           // },
           // {
           //   label: 'Incident - Commiting Offence',
           //   value: 'commitOffence',
           //   type: 'list',
           //   list: 'yesno'
           // },
           // {
           //   label: 'Incident - Behaviour Supporting',
           //   value: 'behaviour',
           //   type: 'list',
           //   list: 'yesno'
           // },
         ],
         academicYears: [
           'Year -10',
           'Year -9',
           'Year -8',
           'Year -7',
           'Year -6',
           'Year -5',
           'Year -4',
           'Year -3',
           'Year -2',
           'Year -1',
           'Year 0',
           'Year 1',
           'Year 2',
           'Year 3',
           'Year 4',
           'Year 5',
           'Year 6',
           'Year 7',
           'Year 8',
           'Year 9',
           'Year 10',
           'Year 11',
           'Year 12',
           'Year 13',
           'Year 14',
           'Year 15',
           'Year 16',
           'Year 17',
           'Year 18',
           'Year 19',
           'Year 20',
         ],
         injurySufferedBy: ['[Student]', 'Staff', 'Other'],
       };

export default constants


import ApiService from './ApiService'

class OrganisationService {
  addOrganisation = async (apiToken, data) => {
    var url = '/api/organisation'

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.post(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:addOrganisation ', result)
    return result
  }

  // myOrganisations = async (apiToken) => {
  //   var url = '/api/my/organisations'

  //   var params = {
  //     fields: 'id,name,primaryColour,secondaryColour,logoUrl,createdAt',
  //     limit: 1000
  //   }

  //   try {
  //     var organisations = await ApiService.get(apiToken, url, params)
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   console.log('OrganisationService:myOrganisations ', organisations)
  //   return organisations
  // }

  getOrganisations = async (apiToken) => {
    var url = '/api/organisation'

    var params = {
      fields: 'id,name,appSettings,enabledModules'
    }

    try {
      var organisations = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:getOrganisations ', organisations)
    return organisations
  }

  getOrganisation = async (apiToken, organisationId) => {
    var url = `/api/organisation/${organisationId}`

    var params = {
      fields: 'id,name,logoUrl,hasSites,createdAt,admin,sharedIncidentReports,sharedSmartPlans,numAllowedUsers,wondeSync,safeguardingLeads,enablePILExtras,aiTermsAccepted',
      limit: 1
    }



    try {
      var organisation = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:getOrganisation ', organisation)
    return organisation
  }

  updateOrganisation = async (apiToken, data) => {
    const organisationId = data.id
    var url = `/api/organisation/${organisationId}`

    // var params = {
    //   fields: 'id,firstName,lastName,email,userName',
    //   limit: 1000
    // }

    var params = {}

    var options = {
      data: data
    }

    try {
      var result = await ApiService.put(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:updateOrganisation ', result)
    return result
  }

  getUserCount = async (apiToken, params, organisationId) => {
    var url = `/api/organisation/${organisationId}/user`

    params.fields = 'id,type'

    try {
      var users = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:getUserCount ', users)

    var userCounts = {}

    if (users?.page?.total) {
      const systemUsers = users.data.filter(user => {
        return user.type === 'user'
      })
      userCounts.systemUsers = systemUsers

      const students = users.data.filter(user => {
        return user.type === 'student'
      })

      userCounts.students = students
    }
    return userCounts
  }

  getRolesForOrg = async (apiToken, organisationId) => {
    var url = `/api/organisation/${organisationId}/role`

    var params = {
      fields: 'id,title,permissions'
    }

    try {
      var roles = await ApiService.get(apiToken, url, params)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:getRolesForOrg ', roles)
    return roles
  }

  deleteOrg = async (apiToken, data) => {
    const orgId = data.id
    var url = `/api/organisation/${orgId}`

    var params = {}

    var options = {}
    try {
      var result = await ApiService.sendDelete(apiToken, url, params, options)
    } catch (err) {
      console.log(err)
    }
    console.log('OrganisationService:deleteOrg ', result)
    return result
  }
}
export default new OrganisationService()

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// utils
import { capitalize } from '../../../utils/text';
import { search, getUniqueValues } from '../../../utils/array';

// Appt Components
import Box from '../../../components/simple/box/Box';
import DataTable from '../../../components/simple/dataTable/DataTable.js';
import Button from '../../../components/simple/button/Button';
import Text from '../../../components/simple/text/Text';

// Services
import FormService from '../../../services/FormService';
import LocalStorageService from '../../../services/LocalStorageService';
import UserService from '../../../services/UserService';

const IncidentSearchResults = props => {
  const apiToken = LocalStorageService.get('apiToken');
  const activeOrg = LocalStorageService.get('activeOrg');

  const [submissions, setSubmissions] = useState([]);
  const [responses, setResponses] = useState([]);

  const history = useHistory();

  const deleteReport = async submissionId => {
    if (window.confirm('Are you sure you want to delete this?')) {
      await FormService.updateSubmission(apiToken, submissionId, {
        status: 'Archived',
      });
      window.location.reload(); // this is not ideal
    }
  };

  const resultColumns = [
    {
      property: 'reference',
      header: <Text>Incident No</Text>,
      render: datum => (
        <Text>{datum.id ? `besmart-${datum.reference}` : ''}</Text>
      ),
    },
    {
      property: 'firstName',
      header: (
        <Text>{capitalize(props?.terms?.serviceUser || 'Service User')}</Text>
      ),
      render: datum => (
        <Text>{`${datum.firstname || ''} ${datum.lastname || ''}`}</Text>
      ),
    },
    {
      property: 'yearGroup',
      header: <Text>Year Group</Text>,
    },
    {
      property: 'dateOfIncident',
      header: <Text>Date Of Incident</Text>,
      render: datum => (
        <Text>
          {moment(datum.dateOfIncident).format('DD/MM/YY') === 'Invalid date'
            ? 'No Date'
            : moment(datum.dateOfIncident).format('DD/MM/YY')}
        </Text>
      ),
    },
    {
      property: 'flag',
      header: <Text>Flag</Text>,
      render: datum => {
        const setColour = async color => {
          await FormService.updateSubmission(apiToken, datum.id, {
            flag: color,
          });

          Array.from(
            document.getElementsByClassName(`flag-${datum.id}`),
          ).forEach(function (element, index, array) {
            element.style.opacity = 0.15;
          });

          document.getElementById(
            `flag-${datum.id}-${color}`,
          ).style.opacity = 1;
        };

        return (
          <Box direction="row" width="100%">
            <Button onClick={() => setColour('none')}>
              <FontAwesomeIcon
                id={`flag-${datum.id}-none`}
                className={`flag-${datum.id}`}
                icon={['fa', 'flag']}
                color="#777"
                style={
                  !['red', 'green', 'yellow'].includes(datum.flag)
                    ? { margin: '1px', opacity: 1 }
                    : { margin: '1px', opacity: 0.15 }
                }
              />
            </Button>
            <Button onClick={() => setColour('green')}>
              <FontAwesomeIcon
                id={`flag-${datum.id}-green`}
                className={`flag-${datum.id}`}
                icon={['fa', 'flag']}
                color="#00FF00"
                style={
                  'green' === datum.flag
                    ? { margin: '1px', opacity: 1 }
                    : { margin: '1px', opacity: 0.15 }
                }
              />
            </Button>
            <Button onClick={() => setColour('yellow')}>
              <FontAwesomeIcon
                id={`flag-${datum.id}-yellow`}
                className={`flag-${datum.id}`}
                icon={['fa', 'flag']}
                color="#FFA500"
                style={
                  'yellow' === datum.flag
                    ? { margin: '1px', opacity: 1 }
                    : { margin: '1px', opacity: 0.15 }
                }
              />
            </Button>
            <Button onClick={() => setColour('red')}>
              <FontAwesomeIcon
                id={`flag-${datum.id}-red`}
                className={`flag-${datum.id}`}
                icon={['fa', 'flag']}
                color="#FF0000"
                style={
                  'red' === datum.flag
                    ? { margin: '1px', opacity: 1 }
                    : { margin: '1px', opacity: 0.15 }
                }
              />
            </Button>
          </Box>
        );
      },
    },
    {
      property: 'actions',
      header: <Text>Actions</Text>,
      render: datum => (
        <>
          <Box direction="row" width="100%">
            {props?.type !== 'smartplan' && (
              <Button
                onClick={() => {
                  history.push(`/incident/${datum.userId}/edit/${datum.id}`);
                }}
                target="_self"
                plain
                children={
                  <Box pad="5px">
                    <FontAwesomeIcon icon={['fal', 'edit']} color="#fe8300" />
                  </Box>
                }
              />
            )}

            {datum.pdfUrl && (
              <>
                <Button
                  target="_blank"
                  plain
                  href={datum.pdfUrl}
                  children={
                    <Box pad="5px">
                      <FontAwesomeIcon
                        icon={['fal', 'download']}
                        color="#fe8300"
                      />
                      {/* <Download color='primary' size='medium' disabled /> */}
                    </Box>
                  }
                />
                <Button
                  target="_blank"
                  plain
                  onClick={() => {
                    deleteReport(datum.id);
                  }}
                  children={
                    <Box pad="5px">
                      <FontAwesomeIcon
                        icon={['fal', 'trash']}
                        color="#fe8300"
                      />
                    </Box>
                  }
                />
              </>
            )}
          </Box>
        </>
      ),
    },
  ];

  return (
    <>
      <DataTable
        columns={resultColumns}
        data={props.results}
        primaryKey="id"
        step={250}
        paginate={true}
        // sort={sort}
        // onSort={setSort}
      />
      {!props.results.length && <Text>There are no results to display.</Text>}
    </>
  );
};

export default IncidentSearchResults;


// const hideValues = false

import ApiService from './ApiService'

class AIService {
  request = async (apiToken, userId, submissionId, action) => {
    let url = '/ai/query/' + action

    if (!userId || !submissionId || !action) { return }
    
    let params = {}
    params.userId = userId
    params.submissionId = submissionId

    let body = {}

    try {
      // Need to change this to a POST
      var response = await ApiService.post(apiToken, url, params, body)
    } catch (err) {
      console.log(err)
    }
    console.log('AIService:request ', response)
    return response
  }

}
export default new AIService()

import React, { useState } from 'react'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import CheckBoxGroup from '../../components/simple/input/CheckBoxGroup'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome.js'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider'
import Grid from '../../components/simple/grid/Grid.js'
import H2 from '../../components/simple/heading/H2.js'
import NotificationLayer from '../../components/compound/notification/NotificationLayer'
import Text from '../../components/simple/text/Text.js'
import LocalStorageService from '../../services/LocalStorageService.js'

function ReportSend (props) {
  const [questions, setQuestions] = useState(props.questions)
  const [localIncidentValues, setLocalIncidentValues] = useState(props.incidentValues)
  const [showSubmitOptions, setShowSubmitOptions] = useState(false)

  const [pendingRedirectUrl, setPendingRedirectUrl] = useState(null)

  const [isSaving, setIsSaving] = useState(false)

  const activeOrg = LocalStorageService.get('activeOrg')

  const previousPage = () => {
    props.previousPage(localIncidentValues)
  }

  const submitForm = (redirectUrl) => {

    var thisRedirectUrl = redirectUrl
    if (pendingRedirectUrl) {
      thisRedirectUrl = pendingRedirectUrl
    }

    props.submitForm(localIncidentValues, 'Published', thisRedirectUrl)
    setShowSubmitOptions(false)
  }

  const checkToSubmit = (redirectUrl = '/serviceusers') => {

    // don't allow multiple submissions
    if (isSaving) { return }
    setIsSaving(true)

    setTimeout(() => { setIsSaving(false) }, 4000)

    if (localIncidentValues?.notify) {
      setPendingRedirectUrl(redirectUrl)
      setShowSubmitOptions(true)
    } else {
      submitForm(redirectUrl)
    }
  }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <H2 margin={{ vertical: 'none' }}>Send Report</H2>
        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

        {showSubmitOptions &&
          <NotificationLayer button1Text='Yes' button1Click={submitForm} button2Text='No' button2Click={() => { setShowSubmitOptions(false) }}>Are you sure you wish to send this report to the selected key contacts?</NotificationLayer>}

        <Form
          // onSubmit={({ value: nextValue }) => {
          //   checkToSubmit(nextValue)
          // }}
          value={localIncidentValues}
        >
          <Grid
            rows={['auto', 'auto', 'auto', 'auto']}
            columns={['auto', 'auto', 'auto', 'auto']}
            gap='small'
            areas={[
              { name: 'main', start: [0, 0], end: [1, 1] }
            ]}
          >
            {props.contacts.length
              ? (
                <FormField
                  label={questions[0].questionText}
                  name={questions[0].key}
                  required
                >
                  <CheckBoxGroup
                    labelKey='display'
                    name={questions[0].key}
                    onChange={event => {
                      // props.setNotifications(event.value)
                      // setLocalNotifications(event.value)
                      setLocalIncidentValues(localIncidentValues => ({
                        ...localIncidentValues,
                        notify: event.value
                      }))

                      // console.log('Group1: ', event.value)
                    }}
                    options={props.contacts}
                    valueKey='email'
                  />
                </FormField>)
              : (
                <Box>
                  <Text>There are no key contacts for {props.incidentValues.studentName.firstName} {props.incidentValues.studentName.lastName}. A key contact can be added on {props.incidentValues.studentName.firstName} {props.incidentValues.studentName.lastName}'s profile. <Anchor href={`/serviceuser/${props.incidentValues.studentName.id}/profile`}><FontAwesome color='brand' icon={['fas', 'link']} /></Anchor></Text>
                </Box>)}
          </Grid>

          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => previousPage()} secondary />
            <Box direction='row'>
              {!isSaving &&
                <>
                  <Button label='Submit Report' onClick={() => checkToSubmit()} secondary margin={{ right: '10px' }} />
                  <Button label='Smart Plan Builder' onClick={() => checkToSubmit(`/behaviour/${props.incidentValues.studentName.id}/edit`)} primary margin={{ right: '10px' }} />
                  {activeOrg.aiTermsAccepted && <Button label='AI Support' onClick={() => checkToSubmit(`/incident/${props.incidentValues.studentName.id}/ai/latest`)} primary />}
                </>}
              {isSaving && <Text color='primary'>Saving, please wait...</Text>}
            </Box>
          </Box>
        </Form>

      </Box>
    </Box>
  )
}
export default ReportSend

import React, { useState, useEffect } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import FormService from '../../services/FormService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import UserTagService from '../../services/UserTagService'

// utils
import { getObjectByValue } from '../../utils/objects'
import Responsive from '../../utils/Responsive'
import { capitalize } from '../../utils/text'

// Grommet
import { ResponsiveContext } from 'grommet'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import CheckBox from '../../components/simple/input/CheckBox.js'
import CheckBoxGroup from '../../components/simple/input/CheckBoxGroup'
import DateTime from '../../components/compound/dateTime/DateTime'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Layer from '../../components/simple/layer/Layer'
import Select from '../../components/simple/input/Select'
import Text from '../../components/simple/text/Text'
import TextArea from '../../components/simple/input/TextArea'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

function ConcernEdit (props) {
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')

  const params = useParams()

  const userId = params.userid

  const [keyContacts, setKeyContacts] = useState([])

  // All Users of type user
  const [userValues, setUserValues] = useState([])

  // All Users of type student
  const [studentValues, setStudentValues] = useState([])

  // This student
  const [studentDetails, setStudentDetails] = useState({
    firstName: '',
    id: params.userid || '',
    lastName: '',
    learnerNumber: '',
    name: ''
  })

  const [submissions, setSubmissions] = useState([])

  const defaultConcern = {
    concernDate: '',
    concernTime: '',
    concern: '',
    recordedBy: '',
    actionDate: '',
    actionTime: '',
    actionedBy: '',
    action: '',
    addToSmartPlan: ''
  }

  // Concern list on display ie. could be filtered
  const [concerns, setConcerns] = useState([])

  // All Concerns
  const [concernsAll, setConcernsAll] = useState([])

  // New Concern detail being added
  const [concernValues, setConcernValues] = useState(defaultConcern)
  const [selectedConcernId, setSelectedConcernId] = useState()

  // Display Concern full screen?
  const [displayConcernOpen, setDisplayConcernOpen] = useState(false)
  const [concernFull, setConcernFull] = useState()

  const [isSaving, setIsSaving] = useState(false)

  // Open and Close Full Concern disaply
  const onOpenDisplayConcern = (concernResponseId) => {
    if (concernResponseId) {
      const selectedConcern = getObjectByValue(concerns, 'id', concernResponseId)

      setConcernFull(selectedConcern.concern)
      setDisplayConcernOpen(true)
    }
  }

  const onCloseDisplayConcern = () => {
    setDisplayConcernOpen(undefined)
  }

  // Edit a Concern
  const onEditConcern = (concernResponseId) => {
    if (concernResponseId) {
      const selectedConcern = getObjectByValue(concerns, 'id', concernResponseId)

      setSelectedConcernId(parseInt(selectedConcern.submission))

      selectedConcern.student = parseInt(selectedConcern.student)
      setConcernValues(selectedConcern)
    }
  }

  const deleteConcern = async (concernResponseId) => {   
    await FormService.updateSubmission(apiToken, concernResponseId, { status: 'Archived' })
    // window.location.reload() // this is not ideal
    await getConcerns()
  }

  const confirmDeleteConcern = async (concernResponseId) => {   
    if (window.confirm('Are you sure you want to delete this?')) {

      const selectedConcern = getObjectByValue(concerns, 'id', concernResponseId)

      const submissionToDelete = parseInt(selectedConcern.submission)
      deleteConcern(submissionToDelete)
    }
  }

  const cancelEdit = async () => {
    setSelectedConcernId(null)
    setConcernValues(defaultConcern)
  }

  const onAddToSmartPlanChange = () => {
    // Do nothing - CheckBox requires an onChange function
  }

  const [questions, setQuestions] = useState([
    {
      questionText: 'Student',
      key: 'student'
    },
    {
      questionText: 'Date of Log',
      key: 'concernDate',
      responseType: 'date'
    },
    {
      questionText: 'Recorded By',
      key: 'recordedBy',
      responseType: 'number'
    },
    {
      questionText: 'Log',
      key: 'concern'
    },
    {
      questionText: 'Action Date',
      key: 'actionDate',
      responseType: 'date'
    },
    {
      questionText: 'Actioned By',
      key: 'actionedBy',
      responseType: 'number'
    },
    {
      questionText: 'Action',
      key: 'action'
    },
    // Hidden questions
    {
      questionText: 'Add to Smart Plan?',
      key: 'addToSmartPlan',
      responseType: 'boolean'
    },
    {
      questionText: 'Time of Log',
      key: 'concernTime'
    },
    {
      questionText: 'Time of Action',
      key: 'actionTime'
    }
  ])

  // Go Back
  const goBack = () => {
    history.goBack()
  }

  const setConcernDateTime = (date, time) => {
    setConcernValues(previousValues => ({
      ...previousValues,
      concernDate: date,
      concernTime: time
    }))
  }

  const setActionDateTime = (date, time) => {
    setConcernValues(previousValues => ({
      ...previousValues,
      actionDate: date,
      actionTime: time
    }))
  }

  const getContacts = async () => {
    const params = {
      fields: 'user,type,key,organisation,value'
    }

    const where = {
      user: studentDetails.id
    }

    let contacts = await UserTagService.get(apiToken, params, where)

    if (contacts) {
      // Filter out the 'keycontacts'
      contacts = contacts.filter((item) => item.key === 'keycontacts')
    }

    if (contacts?.length) {
      const parsedContacts = JSON.parse(contacts[0].value)
      const mappedContacts = parsedContacts.map((contact, index) => ({
        display: <><Box direction='column'><Text weight='bold'>{contact.firstName} {contact.lastName}</Text><Text size='small'>{contact.role}</Text></Box></>,
        email: contact.email,
        firstName: contact.firstName,
        lastName: contact.lastName,
        name: contact.firstName + ' ' + contact.lastName,
        role: contact.role
      }))

      console.log('saved contacts ', mappedContacts)

      setKeyContacts(mappedContacts)
    }
  }

  // Check if the passed in input field has any value
  const checkInputHasContent = (input) => {
    let inputHasContent = false

    switch (typeof (input)) {
      case 'string':
        inputHasContent = input.length > 0
        break
      case 'number':
        inputHasContent = input > 0
        break
      case 'boolean':
        inputHasContent = true
        break
      case 'object':
        inputHasContent = true
        // inputHasContent = input.length > 0
        break
    }

    return inputHasContent
  }

  // Submit form values
  const submitForm = async () => {
    // don't allow multiple submissions
    if (isSaving) { return }
    setIsSaving(true)
    setTimeout(() => { setIsSaving(false) }, 4000)

   // Build output data
    var submitArray = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    // Loop through each question
    questions.forEach(function (question, index) {
      if (checkInputHasContent(concernValues[question.key])) {
        item = {
          questionKey: question.key,
          questionText: question.questionText,
          response: concernValues[question.key],
          responseType: question?.responseType || 'string'
        }

        submitArray.push(item)
      }
    })

    // Add notifications
    var notifyArray = []
    if (concernValues?.notify?.length > 0) {
      let contactFound = {}

      // Build array of notifications
      concernValues.notify.forEach(notification => {
        contactFound = keyContacts.find((contact) => contact.email === notification)
        notifyArray.push({
          firstName: contactFound.firstName,
          lastName: contactFound.lastName,
          email: contactFound.email
        })
      })
    }

    const data = {
      // user: activeUser.id,
      // subject: studentDetails.id,
      user: activeUser.id,
      organisation: activeOrg.id,
      status: 'Published',
      type: 'concern',
      reference: '',
      notify: JSON.stringify(notifyArray),
      responses: submitArray,
      subject: studentDetails.id
    }

    // console.log(data)

    var submissionAdded = await FormService.makeSubmissions(apiToken, data)

    if (selectedConcernId) {
      deleteConcern(selectedConcernId) 
    }

    setIsSaving(false)
    if (submissionAdded.error) {
      NotificationService.error(submissionAdded.error)
    } else {
      NotificationService.success('Log added')

      // Clear input
      setConcernValues(defaultConcern)

      // And get the Concerns again
      getConcerns()
    }
  }

  const getConcerns = async () => {
    const params = {
      orgId: activeOrg.id,
      fields: 'id',
      limit: 300,
      sort: 'id DESC'
    }

    const where = {
      type: 'concern',
      status: 'Published',
      subject: studentDetails.id
      // user: studentDetails.id
    }

    const concernSubmissions = await FormService.getSubmissions(apiToken, params, where)
    if (concernSubmissions.error) {
      setSubmissions(null)
    } else {
      if (concernSubmissions?.data) {
        const submissionIds = concernSubmissions.data.map((submission, index) =>
          submission.id
        )
        setSubmissions(submissionIds)
      }
    }
  }

  const getUsers = async (type = 'user') => {
    const params = {
      fields: 'id,firstName,lastName,reference,type',
      limit: 1000,
      orgId: activeOrg.id,
      type
    }

    setLoading(true)
    const users = await UserService.getUsers(apiToken, params)
    if (!users || users?.error) {
      // NotificationService.error(users.error)
    } else {
      if (users?.error) {
        setUserValues(null)
      } else if (users?.data) {
        if (type === 'user') {
          // Get system users
          var mappedUsers = []
          for (const thisUser of users.data) {
            if (thisUser.firstName && thisUser.lastName) {
              mappedUsers.push({
                id: thisUser.id,
                learnerNumber: thisUser.reference,
                name: thisUser.firstName + ' ' + thisUser.lastName
              })
            }
          }
          mappedUsers = mappedUsers.sort((a, b) => a.name.localeCompare(b.name))
          setUserValues(mappedUsers)
        } else if (type === 'student') {
          var studentUsers = []
          for (const thisUser of users.data) {
            if (thisUser.firstName && thisUser.lastName) {
              studentUsers.push({
                id: thisUser.id,
                learnerNumber: thisUser.reference,
                name: thisUser.firstName + ' ' + thisUser.lastName,
                firstName: thisUser.firstName,
                lastName: thisUser.lastName
              })
            }
          }

          studentUsers = studentUsers.sort((a, b) => a.name.localeCompare(b.name))
          setStudentValues(studentUsers)

          if (userId !== 'new') {
            // If we have a user id in the url then
            // get the details from the list of students
            const studentObject = getObjectByValue(studentUsers, 'id', parseInt(userId))

            setStudentDetails(studentObject)
          }
        }
      }
    }
  }

  // This adds to an array of historical Concerns (savedConcerns) for display purposes
  // key = key to be added
  // response = historical Concern
  const buildConcernHistory = (savedConcerns, response, key) => {
    savedConcerns.find((obj, index) => {
      if (obj.submission === response.submission) {
        if (key === 'id') {
          savedConcerns[index][key] = response.id
        } else {
          savedConcerns[index][key] = response.responseType === 'number' ? parseInt(response.response) : response.response
        }
      }
    })
  }

  const onStudentChange = (student) => {
    setStudentDetails(student)
  }

  // Get all Users and Students on load
  useEffect(() => {
    (async () => {
      await getUsers('user')
      await getUsers('student')

      setLoading(false)
    })()
  }, [])

  // If a different student is selected
  // then get their Concerns
  useEffect(() => {
    (async () => {
      if (!studentDetails?.id) {
        return
      }

      await getConcerns()
      await getContacts()

      setConcernValues(previousValues => ({
        ...previousValues,
        student: studentDetails.id
      }))

      setLoading(false)
    })()
  }, [studentDetails])

  // Submissions have been found, get the Responses
  useEffect(() => {
    (async () => {
      if (submissions?.length) {
        const savedConcerns = []

        submissions.forEach((submission) => {
          // savedConcerns[submission] = {
          //   concernDate: '',
          //   action: ''
          // }
          savedConcerns.push(
            {
              submission: submission,
              concernDate: '',
              action: '',
              id: ''
            }
          )
        })
        const params = {
          fields: 'id,questionKey,response,responseType,submission',
          orgId: activeOrg.id,
          limit: 4000,
          sort: 'id DESC'

        }

        const where = {
          submission: submissions
        }

        const concernResponses = await FormService.getResponses(apiToken, params, where)
        if (concernResponses?.error) {
          NotificationService.error(concernResponses.error)
        } else {
          // const concernToSave = {}

          if (concernResponses?.data.length) {
            concernResponses.data.forEach((response) => {
              if (response.questionKey && response.id) {
                buildConcernHistory(savedConcerns, response, response.questionKey)
                buildConcernHistory(savedConcerns, response, 'id')
              // switch (response.questionKey) {
              //   case 'concernDate':
              //     buildConcernHistory(savedConcerns, response, 'concernDate')
              //     break
              //   case 'action':
              //     buildConcernHistory(savedConcerns, response, 'action')

              //     // Add action id to display full screen later
              //     buildConcernHistory(savedConcerns, response, 'id')
              //     break
              //   default:
              //     break
              // }
              }
            })

            setConcernsAll(savedConcerns)
            setConcerns(savedConcerns)
          }
        }
      } else {
        setConcernsAll(null)
        setConcerns(null)
      }
    })()
  }, [submissions])

  return (
    <Box width='xlarge'>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        {userId === 'new' ? 'New ' : ''}Logs: {userId !== 'new' ? ': ' + (studentDetails?.name || '') : ''}
      </BreadcrumbBar>

      <Box gridArea='main' background='white' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Form
            //   validate='blur'
            onChange={nextValue => {
              setConcernValues(nextValue)
            }}
            onSubmit={({ value: nextValue }) => {
              submitForm(nextValue)
            }}
            value={concernValues}
          >
            <Responsive
              rows={['auto']}
              columns={{
                small: ['auto'],
                medium: ['1/2', '1/2'],
                large: ['1/2', '1/2'],
                xlarge: ['1/2', '1/2']
              }}
              gap='medium'
            >
              <Box pad='small' border={{ size: 'xsmall' }}>
                <Text size='xlarge' weight='bold'>New Log {selectedConcernId}</Text>

                <Divider margin={{ top: 'small', bottom: 'medium' }} />

                {/* Student */}
                <FormField
                  label={capitalize(props?.terms?.serviceUser || 'Service User')}
                  name='student'
                >
                  <Select
                    name='student'
                    labelKey='name'
                    onChange={({ option }) => onStudentChange(option)}
                    options={studentValues}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>

                {/* Date */}
                <FormField
                  label='Date of Log'
                  name='concernDate'
                >
                  <DateTime
                    date={concernValues.concernDate}
                    time={concernValues.concernTime}
                    name='concernDate'
                    setDateTime={setConcernDateTime}
                  />
                </FormField>

                {/* Recorded By */}
                <FormField
                  label='Recorded By'
                  name='recordedBy'
                >
                  <Select
                    name='recordedBy'
                    labelKey='name'
                    options={userValues}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>

                {/* Concern */}
                <FormField
                  label='Log'
                  name='concern'
                >
                  <TextArea
                    className='concern'
                    data-min-rows='3'
                    name='concern'
                    rows='3'
                  />
                </FormField>

                <Divider margin={{ vertical: 'medium' }} />

                <Text size='large' weight='bold'>Action</Text>

                <FormField
                  label='Action Date'
                  name='actionDate'
                >
                  <DateTime
                    date={concernValues.actionDate}
                    time={concernValues.actionTime}
                    name='actionDate'
                    setDateTime={setActionDateTime}
                  />
                </FormField>

                {/* Actioned By */}
                <FormField
                  label='Actioned By'
                  name='actionedBy'
                >
                  <Select
                    name='actionedBy'
                    labelKey='name'
                    options={userValues}
                    valueKey={{ key: 'id', reduce: true }}
                  />
                </FormField>

                {/* Action */}
                <FormField
                  label='Action'
                  name='action'
                >
                  <TextArea
                    className='action'
                    data-min-rows='3'
                    name='action'
                    rows='3'
                  />
                </FormField>

                <Divider margin={{ vertical: 'medium' }} />

                <Text size='large' weight='bold'>Smart Plan</Text>

                <FormField
                  // label='Add this concern to the xxx Smart Plan?'
                  name='addToSmartPlan'
                >
                  <CheckBox
                    // color='white'
                    label={<Text>Add this log to the {`${props?.terms?.serviceUser || 'Service User'}`}'s Smart Plan?</Text>}
                    name='addToSmartPlan'
                    onChange={onAddToSmartPlanChange}
                  />
                </FormField>

                {!isSaving && <Button label='Save' primary type='submit' />}
                {isSaving && <Text color='primary'>Saving, please wait...</Text>}
                {!isSaving && selectedConcernId && <Button label='Cancel' onClick={() => cancelEdit()} secondary margin={{ top: 'small' }} />}



              </Box>
              <Box>
                <Text size='xlarge' weight='bold'>History</Text>

                <Box direction='row-responsive' gap='small' margin={{ top: 'medium' }}>
                  {/* <TextInput
                    icon={<FontAwesome icon={['fal', 'search']} />}
                    onChange={event => filterDocuments(event.target.value)}
                    reverse
                    value={filter}
                  /> */}
                </Box>
                {concernsAll?.length > 0
                  ? concernsAll?.map((item) => (
                    item.concern
                      ? <Box direction='column' key={item.id} margin={{ horizontal: '10px', top: '10px' }}>
                          <Box direction='row' justify='between'>
                            <Text color='brand' size='medium'>{item.concernDate}</Text>
                            <Box direction='row' gap='medium' justify='end'>
                              <Button icon={<Text><FontAwesome icon={['fal', 'eye']} /></Text>} onClick={() => onOpenDisplayConcern(item.id)} plain />
                              <Button icon={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} onClick={() => onEditConcern(item.id)} plain />
                              <Button icon={<Text><FontAwesome icon={['fal', 'trash']} /></Text>} onClick={() => confirmDeleteConcern(item.id)} plain />
                            </Box>
                          </Box>
                          <Text size='small' truncate weight='bold'>{item.concern || 'No concern recorded'}</Text>
                          {/* {urlParams.staffid !== undefined &&
                            <Text size='small'>By: {item.ownerName}</Text>} */}
                        </Box>
                      : <></>)
                  )
                  : <Text>No Logs available</Text>}
              </Box>
            </Responsive>

            {displayConcernOpen &&
              <ResponsiveContext.Consumer>
                {responsive => (
                  <Layer
                    margin={responsive === 'small' ? 'medium' : 'large'}
                    onClickOutside={onCloseDisplayConcern}
                    onEsc={onCloseDisplayConcern}
                    position='center'
                    responsive={false}
                  >
                    <Box
                      flex
                      overflow='auto'
                      pad='medium'
                      width={responsive === 'small' ? 'medium' : 'large'}
                    >
                      <Text>{concernFull}</Text>
                    </Box>
                  </Layer>)}
              </ResponsiveContext.Consumer>}

            <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

            <Box direction='row' justify='between' margin={{ top: 'medium' }}>
              <Button label='< Back' onClick={() => goBack()} secondary />
            </Box>
          </Form>
        </Box>
      </Box>
    </Box>
  )
}

export default ConcernEdit

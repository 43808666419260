import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import FormService from '../../services/FormService';
import LocalStorageService from '../../services/LocalStorageService';
import NotificationService from '../../services/NotificationService';
import OrganisationTagService from '../../services/OrganisationTagService';
import TechniqueService from '../../services/TechniqueService';
import UserService from '../../services/UserService';
import UserTagService from '../../services/UserTagService';

// Spinner
import PulseLoader from 'react-spinners/PulseLoader';

// utils
import { capitalize, removeSpaces } from '../../utils/text';

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor';
import Box from '../../components/simple/box/Box';
import Meter from '../../components/simple/meter/Meter';
import Tabs from '../../components/simple/tabs/Tabs.js';
import Text from '../../components/simple/text/Text';

import BehaviourDetails from './IncidentEdit-BehaviourDetails';
import DeescalationTechniques from './IncidentEdit-DeescalationTechniques';
import IncidentReport from './IncidentEdit-IncidentReport';
import MedicalIntervention from './IncidentEdit-MedicalIntervention';
import PhysicalTechniques from './IncidentEdit-PhysicalTechniques';
import PostIncidentLearning from './IncidentEdit-PostIncidentLearning';
import ReportSend from './IncidentEdit-ReportSend';

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar';

const Screens = Object.freeze({
  IncidentReportScreen: 0,
  BehaviourDetailsScreen: 1,
  DeescalationTechniquesScreen: 2,
  PhysicalTechniquesScreen: 3,
  PostIncidentLearningScreen: 4,
  SendReportScreen: 5,
});

function IncidentEdit(props) {
  const history = useHistory();

  if (props?.terms?.serviceUser) {
    props.terms.serviceUser =
      props?.terms?.serviceUser.charAt(0).toUpperCase() +
      props?.terms?.serviceUser.slice(1);
  }

  const [reference, setReference] = useState('');
  const [loading, setLoading] = useState(false);
  const [userValues, setUserValues] = useState([]);
  const [studentValues, setStudentValues] = useState([]);
  const [incidentValues, setIncidentValues] = useState({
    // Incident Report
    studentName: '',
    dateOfIncident: '',
    timeOfIncident: '',
    location: '',
    completedBy: '',
    staffInvolved: [],
    studentsInvolved: [],
    witnesses: '',
    hurtingSelf: '',
    hurtingOthers: '',
    propertyDamage: '',
    behaviour: '',
    commitOffence: '',
    followPlanQuestion: '',
    followPlanResponse: '',
    bestInterestQuestion: 'No',
    bestInterest: '',
    dynamicRisk: '',
    dynamicRiskInformation: '',
    // Behaviour Details
    lowLevelBehaviour: '',
    lowLevelResponse: '',
    lowLevelStrategy: '',
    mediumLevelBehaviour: '',
    mediumLevelResponse: '',
    mediumLevelStrategy: '',
    highLevelBehaviour: '',
    highLevelResponse: '',
    highLevelStrategy: '',
    // Medical Intervention
    injurySufferedBy: '',
    injury: '',
    treatment: '',
    otherConcerns: '',
    // Deescalation Techniques - these will be overidden
    // with the techniques set up for the organisation
    calmTalking: '',
    listening: '',
    optionsOffered: '',
    socialSpace: '',
    plannedIgnoring: '',
    negotiation: '',
    successReminded: '',
    otherTechniques: '',
    contingentTouch: '',
    otherTechniquesInfo: '',
    // Physical techniques - these will be overidden
    // with the techniques set up for the organisation
    positiveStrategies: '',
    doubleElbow: '',
    caringGuide: '',
    wrap: '',
    figureFour: '',
    singleElbow: '',
    singleElbowSeats: '',
    otherPhysicalTechniques: '',
    friendlyEscort: '',
    seatedWrap: '',
    otherPhysicalTechniquesInfo: '',
    // Post Incident Learning
    recoveryBehaviour: '',
    recoveryResponse: '',
    recoveryStrategy: '',
    studentBehaviour: '',
    studentResponse: '',
    studentStrategy: '',
    likelyTriggers: '',
    adjustments: '',
    restrictedPhysicalInterventionUsed: 'No',
    physicalInterventionUsed: 'No',
    medicalInterventionRequired: 'No',
  });

  const [interventions, setIntervention] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const [deescalationTechniques, setDeescalationTechniques] = useState([]);
  const [physicalTechniques, setPhysicalTechniques] = useState([]);

  const [userDetails, setUserDetails] = useState({
    organisation: 0,
    role: 0,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    jobtitle: '',
  });

  const [keyContacts, setKeyContacts] = useState([]);

  const [meterValue, setMeterValue] = useState();

  const [responses, setResponses] = useState();
  const [goingBack, setGoingBack] = useState(false);

  // Go Back
  const goBack = () => {
    history.goBack();
  };

  const previousPage = incidentDetailsToStore => {
    setGoingBack(true);
    if (tabIndex === 0) {
      goBack();
    } else {
      setIncidentValues(incidentDetailsToStore);
      const prevPageIndex = tabIndex - 1;
      onActiveTab(prevPageIndex);
    }
  };

  const nextPage = incidentDetailsToStore => {
    setGoingBack(false);
    setIncidentValues(incidentDetailsToStore);
    // if (tabIndex === tabContent.length - 1) {
    //   submitForm()
    // } else {
    const nextPageIndex = tabIndex + 1;
    onActiveTab(nextPageIndex);
    // }
  };

  // Submit form values
  const submitForm = async (
    formDetailsToSave,
    status,
    redirectUrl = '/serviceusers',
  ) => {
    // If no data added just exit
    if (formDetailsToSave === true) {
      NotificationService.warning(
        'No fields have been changed. Details have not been submitted',
      );
      return;
    }

    var submitArray = [];
    var item = {
      key: '',
      questionText: '',
      answer: '',
    };

    // Loop through each form value
    for (var key in formDetailsToSave) {
      // And questions on each page of the form
      questions.forEach(function (pageOfQuestions, index) {
        // To match them up
        var found = pageOfQuestions.questions.find(
          question => question.key === key,
        );

        // if (found && formDetailsToSave[key]) {
        if (found && checkInputHasContent(formDetailsToSave[key])) {
          // console.log(found.questionText)
          let response = null;

          if (key === 'studentName') {
            response = formDetailsToSave[key].id;
          } else {
            response = formDetailsToSave[key];
          }

          // Stringify arrays
          if (key === 'staffInvolved') {
            response = JSON.stringify(formDetailsToSave[key]);
          }

          if (key === 'studentsInvolved') {
            response = JSON.stringify(formDetailsToSave[key]);
          }

          item = {
            questionKey: key,
            questionText: found.questionText,
            response: response,
            responseType: found?.responseType || 'string',
          };
          submitArray.push(item);
        }
      });
    }

    // Get action button result
    // submitArray.push({ key: 'bestInterestQuestion', questionText: 'Was your action in the best interest of the [student]?', answer: actionButton })
    // console.log(submitArray)

    // Add interventions if there are any
    if (interventions.length > 0) {
      const injuries = [];

      interventions.forEach(intervention => {
        injuries.push(intervention);
      });

      submitArray.push({
        // organisation: activeOrganisation.id,
        questionKey: 'interventions',
        questionText: 'Interventions',
        response: JSON.stringify(injuries),
      });
    }

    // Add deescalationtechniques
    if (deescalationTechniques.length > 0) {
      submitArray.push({
        questionKey: 'deescalationTechniques',
        questionText: 'Deescalation Techniques',
        response: JSON.stringify(deescalationTechniques),
      });
    }

    // add physical techniques
    if (physicalTechniques.length > 0) {
      submitArray.push({
        questionKey: 'physicalTechniques',
        questionText: 'Physical Techniques',
        response: JSON.stringify(physicalTechniques),
      });
    }

    // Add notifications
    if (formDetailsToSave?.notify?.length > 0) {
      // if (notifications.length > 0) {
      var notifyArray = [];

      let contactFound = {};

      // Build array of notifications
      // notifications.forEach(notification => {
      formDetailsToSave.notify.forEach(notification => {
        contactFound = keyContacts.find(
          contact => contact.email === notification,
        );
        notifyArray.push({
          firstName: contactFound.firstName,
          lastName: contactFound.lastName,
          email: contactFound.email,
        });
      });
    }

    // Build output data
    const data = {
      user: formDetailsToSave.studentName.id,
      organisation: activeOrg.id,
      status: status,
      type: 'incidentreport',
      reference: reference,
      notify: JSON.stringify(notifyArray),
      responses: submitArray,
      author: activeUser.id,
      formId: props.formId,
    };

    console.log(data);
    // if (userId === 'new') {
    console.log('Notify ', notifications);
    var submissionAdded = await FormService.makeSubmissions(apiToken, data);
    if (submissionAdded.error) {
      NotificationService.error(submissionAdded.error);
    } else {
      NotificationService.success('Incident report added');
      history.push(redirectUrl);
    }
    // }
  };

  // This is a lookup to find the questions tabId
  // for the relevant technique as those questions will be overwritten
  const techniqueTabLookup = {
    deescalationTechniques: Screens.DeescalationTechniquesScreen,
    physicalTechniques: Screens.PhysicalTechniquesScreen,
  };

  const [questions, setQuestions] = useState([
    // const questions = [
    {
      tabId: Screens.IncidentReportScreen,
      questions: [
        {
          questionText: `${capitalize(
            props?.terms?.serviceUser || 'Service User',
          )} Name`,
          key: 'studentName',
        },
        {
          questionText: 'Date and Time of Incident',
          key: 'dateOfIncident',
          responseType: 'date',
        },
        // Time not Used but leave in otherwise will
        // need to renumber everything in IcidentReport
        {
          questionText: 'Time of Incident',
          key: 'timeOfIncident',
          responseType: 'time',
        },
        {
          questionText: 'Location',
          key: 'location',
        },
        {
          questionText: 'Report completed by',
          key: 'completedBy',
        },
        {
          questionText: 'Name of staff involved',
          key: 'staffInvolved',
        },
        {
          questionText: `Other ${
            props?.terms?.serviceUserPlural || 'service users'
          } involved`,
          key: 'studentsInvolved',
        },
        {
          questionText: 'Names of witnesses',
          key: 'witnesses',
        },
        {
          questionText: 'Hurting self',
          key: 'hurtingSelf',
          responseType: 'boolean',
        },
        {
          questionText: 'Hurting others',
          key: 'hurtingOthers',
          responseType: 'boolean',
        },
        {
          questionText: 'Committing an offence',
          key: 'commitOffence',
          responseType: 'boolean',
        },
        {
          questionText: 'Damage to property',
          key: 'propertyDamage',
          responseType: 'boolean',
        },
        {
          questionText:
            'Other behaviour?',
          key: 'behaviour',
          responseType: 'boolean',
        },
        {
          questionText: 'Did you follow the plan?',
          key: 'followPlanQuestion',
          responseType: 'boolean',
        },
        {
          questionText: 'Please Elaborate',
          key: 'followPlanResponse',
        },
        {
          questionText: `Was your action in the best interest of the ${
            props?.terms?.serviceUser || 'service user'
          }?`,
          key: 'bestInterestQuestion',
          responseType: 'boolean',
        },
        {
          // NOTE: This is hardcoded on the form field
          // as the parameter wasn't updating when set here
          questionText: `Why was this ${
            incidentValues.bestInterestQuestion === 'No' ? 'not' : ''
          } in the best interest of the ${
            props?.terms?.serviceUser || 'service user'
          }?`,
          key: 'bestInterest',
        },
        {
          questionText: 'Did you make a dynamic risk assessment?',
          key: 'dynamicRisk',
        },
        {
          questionText: 'Please provide more information',
          key: 'dynamicRiskInformation',
        },

        {
          questionText: 'Breathing monitored',
          key: 'breathingMonitored',
        },
        {
          questionText: 'Duration (in minutes) of physical intervention',
          key: 'physicalInterventionDuration',
          responseType: 'number',
        },
        {
          questionText: 'Number of staff involved',
          key: 'numberStaffInvolved',
        },
        {
          questionText: 'Injury suffered by',
          key: 'injurySufferedBy',
        },
        {
          questionText: 'Injury',
          key: 'injury',
        },
        {
          questionText: 'Treatment',
          key: 'treatment',
        },
        {
          questionText: 'Any other concerns / any other information',
          key: 'otherConcerns',
        },
        {
          questionText: 'Was RESTRICTIVE physical intervention used?',
          key: 'restrictedPhysicalInterventionUsed',
        },
        {
          questionText: 'Was anybody injured?',
          key: 'medicalInterventionRequired',
        },
        {
          questionText: 'Was physical intervention used?',
          key: 'physicalInterventionUsed',
        },
        {
          questionText: 'Name',
          key: 'injurySufferedName',
        },
        {
          questionText: 'Treated By',
          key: 'injuryTreatedBy',
        },
      ],
    },
    {
      tabId: Screens.BehaviourDetailsScreen,
      questions: [
        {
          questionText: `What Low Level Behaviour did the ${
            props?.terms?.serviceUser || 'Service User'
          } show?`,
          key: 'lowLevelBehaviour',
        },
        {
          questionText: 'How did you respond?',
          key: 'lowLevelResponse',
        },
        {
          questionText: 'Alternative strategies that might be tried next time.',
          key: 'lowLevelStrategy',
        },
        {
          questionText: `What Medium Level Behaviour did the ${
            props?.terms?.serviceUser || 'Service User'
          } show?`,
          key: 'mediumLevelBehaviour',
        },
        {
          questionText: 'How did you respond?',
          key: 'mediumLevelResponse',
        },
        {
          questionText: 'Alternative strategies that might be tried next time.',
          key: 'mediumLevelStrategy',
        },
        {
          questionText: `What High Level Behaviour did the ${
            props?.terms?.serviceUser || 'Service User'
          } show?`,
          key: 'highLevelBehaviour',
        },
        {
          questionText: 'How did you respond?',
          key: 'highLevelResponse',
        },
        {
          questionText: 'Alternative strategies that might be tried next time.',
          key: 'highLevelStrategy',
        },
        {
          questionText: 'Description of the incident.',
          key: 'incidentDescription',
        },
        {
          questionText: `What Recovery Behaviour did the ${
            props?.terms?.serviceUser || 'Service User'
          } show?`,
          key: 'recoveryBehaviour',
        },
        {
          questionText: 'How did you respond?',
          key: 'recoveryResponse',
        },
        {
          questionText: 'Alternative strategies that might be tried next time',
          key: 'recoveryStrategy',
        },
      ],
    },
    {
      tabId: Screens.DeescalationTechniquesScreen,
      questions: [],
    },
    {
      tabId: Screens.PhysicalTechniquesScreen,
      questions: [],
    },
    {
      tabId: Screens.PostIncidentLearningScreen,
      questions: [
        {
          questionText: `MOVED What Recovery Behaviour did the ${
            props?.terms?.serviceUser || 'Service User'
          } show?`,
          key: 'MOVEDrecoveryBehaviour',
        },
        {
          questionText: 'MOVED How did you respond?',
          key: 'MOVEDrecoveryResponse',
        },
        {
          questionText:
            'MOVED Alternative strategies that might be tried next time',
          key: 'MOVEDrecoveryStrategy',
        },
        {
          questionText: `What happened from the ${
            props?.terms?.serviceUser || 'Service User'
          }'s point of view?`,
          key: 'studentBehaviour',
        },
        {
          questionText: `How did the ${
            props?.terms?.serviceUser || 'Service User'
          } feel?`,
          key: 'studentResponse',
        },
        {
          questionText: `What will the ${
            props?.terms?.serviceUser || 'Service User'
          } do differently next time they feel that way?`,
          key: 'studentStrategy',
        },
        {
          questionText: 'Likely triggers',
          key: 'likelyTriggers',
        },
        {
          questionText: `What reasonable adjustments could be made (in the future) to support the ${
            props?.terms?.serviceUser || 'Service User'
          }?`,
          key: 'adjustments',
        },
        {
          questionText: `Comments`,
          key: 'comments',
        },
        {
          questionText: 'Bullying',
          key: 'Bullying',
        },
        {
          questionText: 'Racist',
          key: 'Racist',
        },
        {
          questionText: 'Sexist',
          key: 'Sexist',
        },
        {
          questionText: 'Disability',
          key: 'Disability',
        },
        {
          questionText: 'Homophobic',
          key: 'Homophobic',
        },
        {
          questionText: 'Biphobic',
          key: 'Biphobic',
        },
        {
          questionText: 'Use of derogatory language',
          key: 'UseOfDerogatoryLanguage',
        },
        {
          questionText: 'Sexual harassment',
          key: 'Sexualharassment',
        },
        {
          questionText: `${props?.terms?.serviceUser || 'Student'} On ${
            props?.terms?.serviceUser || 'Student'
          }`,
          key: 'StudentOnStudent',
        },
        {
          questionText: `Self-injurious Behaviour`,
          key: 'SelfInjuriousBehaviour',
        },
        {
          questionText: `Suspension`,
          key: 'Suspension',
        },
        {
          questionText: `Permanent exclusion`,
          key: 'PermanentExclusion',
        },
      ],
    },
    {
      tabId: Screens.SendReportScreen,
      questions: [
        {
          questionText:
            'Which key contacts should receive a copy of this report?',
          key: 'notify',
        },
      ],
    },
  ]);

  const apiToken = LocalStorageService.get('apiToken');
  const activeOrg = LocalStorageService.get('activeOrg');
  const activeUser = LocalStorageService.get('userDetails');

  const params = useParams();

  const userId = params.userid;
  const formId = params.formId;

  const getContacts = async studentId => {
    const params = {
      fields: 'user,type,key,organisation,value',
    };

    const where = {
      user: studentId || incidentValues.studentName.id,
    };

    let contacts = await UserTagService.get(apiToken, params, where);

    if (contacts) {
      // Filter out the 'keycontacts'
      contacts = contacts.filter(item => item.key === 'keycontacts');
    }

    if (contacts?.length) {
      const parsedContacts = JSON.parse(contacts[0].value);
      const mappedContacts = parsedContacts.map((contact, index) => ({
        display: (
          <>
            <Box direction="column">
              <Text weight="bold">
                {contact.firstName} {contact.lastName}
              </Text>
              <Text>{contact.role}</Text>
            </Box>
          </>
        ),
        email: contact.email,
        firstName: contact.firstName,
        lastName: contact.lastName,
        name: contact.firstName + ' ' + contact.lastName,
        role: contact.role,
      }));

      console.log('saved contacts ', mappedContacts);

      setKeyContacts(mappedContacts);
    }
  };

  const getIncidentPlans = async userId => {
    let params = {
      fields: 'id,author,status,reference',
      limit: 1,
      sort: 'createdAt DESC',
    };

    let where = {
      organisation: activeOrg.id,
      user: userId,
      type: 'incidentReport',
      // status: 'Draft',
      author: activeUser.id,
    };

    if (formId) {
      where.status = 'Published';
      where.id = formId;
      delete where.author;
    }

    // don't filter by author if smart plans are shared
    if (activeOrg.sharedIncidentReports) {
      delete where.author;
    }

    const formSubmissions = await FormService.getSubmissions(
      apiToken,
      params,
      where,
    );

    // If latest submission is Draft display the responses to edit
    // otherwise start a new one
    if (
      !formSubmissions?.error &&
      (formSubmissions.data[0].status === 'Draft' || formId)
    ) {
      params = {
        fields: 'questionKey,response,responseType',
        limit: 2000,
      };

      // Set the reference as this is an edit
      setReference(formSubmissions.data[0].reference);

      where = {
        submission: formSubmissions.data[0].id,
      };

      const formResponses = await FormService.getResponses(
        apiToken,
        params,
        where,
      );

      // Get responses and convert into correct format
      if (formResponses.data.length > 0) {
        const mappedResponses = {};
        for (const response of formResponses.data) {
          if (response.responseType === 'number') {
            response.response = parseInt(response.response);
          }
          mappedResponses[response.questionKey] = response.response;
        }

        setResponses(mappedResponses);
      }
    }
  };

  // Tab controls
  const [tabIndex, setTabIndex] = useState(0);
  const onActiveTab = nextIndex => {
    setMeterValue(((nextIndex + 1) * 100) / questions.length);
    setTabIndex(nextIndex);
  };

  const tabContent = [
    {
      name: 'Incident Report',
      hideTitle: true,
      content: (
        <IncidentReport
          terms={props?.terms}
          getContacts={getContacts}
          getIncidentPlans={getIncidentPlans}
          previousPage={previousPage}
          nextPage={nextPage}
          submitForm={submitForm}
          incidentValues={incidentValues}
          formId={formId}
          setIncidentValues={setIncidentValues}
          questions={questions[Screens.IncidentReportScreen].questions}
          setStudentValues={setStudentValues}
          studentValues={studentValues}
          userId={userId}
          userValues={userValues}
          setUserValues={setUserValues}
          interventions={interventions}
          setIntervention={setIntervention}
        />
      ),
    },
    {
      name: 'Behaviour Details',
      hideTitle: true,
      content: (
        <BehaviourDetails
          previousPage={previousPage}
          nextPage={nextPage}
          submitForm={submitForm}
          incidentValues={incidentValues}
          formId={formId}
          questions={questions[Screens.BehaviourDetailsScreen].questions}
          setIncidentValues={setIncidentValues}
        />
      ),
    },
    {
      name: 'Deescalation Techniques',
      hideTitle: true,
      content: (
        <DeescalationTechniques
          terms={props?.terms}
          previousPage={previousPage}
          nextPage={nextPage}
          submitForm={submitForm}
          incidentValues={incidentValues}
          formId={formId}
          questions={questions[Screens.DeescalationTechniquesScreen].questions}
          setDeescalationTechniques={setDeescalationTechniques}
          setIncidentValues={setIncidentValues}
        />
      ),
    },
    {
      name: 'Physical Techniques',
      hideTitle: true,
      content: (
        <PhysicalTechniques
          terms={props?.terms}
          previousPage={previousPage}
          nextPage={nextPage}
          submitForm={submitForm}
          incidentValues={incidentValues}
          formId={formId}
          questions={questions[Screens.PhysicalTechniquesScreen].questions}
          setPhysicalTechniques={setPhysicalTechniques}
          setIncidentValues={setIncidentValues}
          goingBack={goingBack}
        />
      ),
    },
    {
      name: 'Post Incident Learning',
      hideTitle: true,
      content: (
        <PostIncidentLearning
          previousPage={previousPage}
          nextPage={nextPage}
          submitForm={submitForm}
          incidentValues={incidentValues}
          formId={formId}
          questions={questions[Screens.PostIncidentLearningScreen].questions}
          setIncidentValues={setIncidentValues}
          enablePILExtras={activeOrg.enablePILExtras}
        />
      ),
    },
    {
      name: 'Send Report',
      hideTitle: true,
      content: (
        <ReportSend
          previousPage={previousPage}
          nextPage={nextPage}
          submitForm={submitForm}
          contacts={keyContacts}
          incidentValues={incidentValues}
          formId={formId}
          questions={questions[Screens.SendReportScreen].questions}
          setNotifications={setNotifications}
        />
      ),
    },
  ];

  const getRankings = async () => {
    // get the technique rankings
    const rankings = await TechniqueService.getRankingsForStudent(userId);

    // convert the response to an object using the ranking name as a key
    var newPRankings = {};
    if (rankings?.data?.physical) {
      for (const ranking of rankings.data.physical) {
        const key = removeSpaces(ranking.name.toLowerCase());
        newPRankings[key] = ranking;
      }
    }

    var newDRankings = {};
    if (rankings?.data?.deescalation) {
      for (const ranking of rankings.data.deescalation) {
        const key = removeSpaces(ranking.name.toLowerCase());
        newDRankings[key] = ranking;
      }
    }

    return {
      physical: newPRankings,
      deescalation: newDRankings,
    };
  };

  const getTechniques = async (technique, rankings) => {
    // Get organisation's deescalation/physical techniques
    // and add them to the relevant tab of questions
    // which is identifed in techniqueTabLookup
    const params = {
      fields: 'value',
      limit: 1,
      orgId: activeOrg.id,
    };

    const where = {
      organisation: activeOrg.id,
      type: 'besmart',
      key: technique,
    };

    const techniques = await OrganisationTagService.get(
      apiToken,
      params,
      where,
    );

    if (techniques) {
      const parsedTechniques = JSON.parse(techniques[0].value);

      let mappedTechnique = {};

      const techniqueQuestions = {
        tabId: techniqueTabLookup[technique],
        questions: [],
      };

      for (const technique of parsedTechniques) {
        // If technique is marked for selection
        // then convert it into the correct format
        // and save to new array
        if (technique.selected) {
          mappedTechnique = {};

          mappedTechnique.key = removeSpaces(technique.technique.toLowerCase());
          mappedTechnique.responseType = 'number';
          mappedTechnique.questionText = technique.technique;

          // calculate the ranking
          mappedTechnique.ranking = 0;
          if (rankings?.[mappedTechnique.key]?.average) {
            mappedTechnique.ranking = rankings[mappedTechnique.key]?.average;
          }
          // display the ranking if there is one
          if (mappedTechnique.ranking > 0) {
            mappedTechnique.questionText += ` (Effectiveness: ${mappedTechnique.ranking})`;
          }

          techniqueQuestions.questions.push(mappedTechnique);
        }
      }

      // sort by ranking
      techniqueQuestions.questions = techniqueQuestions.questions.sort(
        (x, y) => y.ranking - x.ranking,
      );

      // Add the Notes question
      techniqueQuestions.questions.push({
        questionText: 'Other',
        key:
          technique === 'deescalationTechniques'
            ? 'otherTechniques'
            : 'otherPhysicalTechniques',
        responseType: 'number',
      });

      techniqueQuestions.questions.push({
        questionText: 'Other - Please elaborate',
        key:
          technique === 'deescalationTechniques'
            ? 'otherTechniquesInfo'
            : 'otherPhysicalTechInfo',
      });

      const newQuestions = questions;
      newQuestions[techniqueTabLookup[technique]] = techniqueQuestions;
      setQuestions(newQuestions);
    }
  };

  // Check if the passed in input field has any value
  const checkInputHasContent = input => {
    let inputHasContent = false;

    switch (typeof input) {
      case 'string':
        inputHasContent = input.length > 0;
        break;
      case 'number':
        inputHasContent = input > 0;
        break;
      case 'boolean':
        break;
      case 'object':
        inputHasContent = true;
        // inputHasContent = input.length > 0
        break;
    }

    return inputHasContent;
  };

  // Map the form values into format required for api submission
  //   const mapForm = (values) => {
  //     var newArray = []
  //     var newObject = {
  //       key: '',
  //       questionText: '',
  //       answer: ''
  //     }
  //     for (var key in values) {
  //       var found = questions.find(q => q.key === key)
  //       if (found) {
  //         console.log(found.questionText)
  //         newObject = {
  //           key: key,
  //           questionText: found.questionText,
  //           answer: values[key]
  //         }
  //         newArray.push(newObject)
  //       }
  //     }
  //     console.log(newArray)
  //   }

  const getUsers = async (type = 'user') => {
    const params = {
      fields: 'id,firstName,lastName,reference,type',
      limit: 1000,
      orgId: activeOrg.id,
      type,
    };

    setLoading(true);
    const users = await UserService.getUsers(apiToken, params);
    if (!users || users?.error) {
      // NotificationService.error(users.error)
    } else {
      // if (!unmounted) {
      if (users?.error) {
        setUserValues(null);
      } else if (users?.data) {
        if (type === 'user') {
          // Get system users
          var mappedUsers = [];
          for (const thisUser of users.data) {
            if (thisUser.firstName && thisUser.lastName) {
              mappedUsers.push({
                id: thisUser.id,
                learnerNumber: thisUser.reference,
                name:
                  (thisUser.firstName || '') + ' ' + (thisUser.lastName || ''),
              });
            }
          }
          mappedUsers = mappedUsers.sort((a, b) =>
            a.name.localeCompare(b.name),
          );

          setUserValues(mappedUsers);
        } else if (type === 'student') {
          var studentUsers = [];
          for (const thisUser of users.data) {
            if (thisUser.firstName && thisUser.lastName) {
              studentUsers.push({
                id: thisUser.id,
                learnerNumber: thisUser.reference,
                name: thisUser.firstName + ' ' + thisUser.lastName,
              });
            }
          }
          studentUsers = studentUsers.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          setStudentValues(studentUsers);
        }
      }
      // }
    }
  };

  const getUserDetails = async (userId, type = 'user') => {
    const params = {
      fields:
        'id,reference,firstName,dob,lastName,email,userName,ethnicity,gender,createdAt',
      limit: 1,
      orgId: activeOrg.id,
      type,
    };

    const userDetails = await UserService.getUser(apiToken, params, userId);
    if (!userDetails || userDetails?.error) {
      // NotificationService.error(userDetails.error)
    } else {
      // if (!unmounted) {
      if (userDetails?.data) {
        setUserDetails(userDetails.data[0]);

        setIncidentValues(incidentValues => ({
          ...incidentValues,
          // studentName: parseInt(userId),
          studentName: userDetails.data[0],
          completedBy: activeUser.id,
        }));
      }
      // }
    }
  };

  const getAndRankTechniques = async () => {
    // manually passing the variable through as a quick fix
    var rankings = {};
    if (userId !== 'new') {
      rankings = await getRankings();
    }
    await getTechniques('deescalationTechniques', rankings?.deescalation);
    await getTechniques('physicalTechniques', rankings?.physical);
  };

  // Basically componentDidMount
  useEffect(() => {
    (async () => {
      await getUsers('user');
      await getUsers('student');
      await getAndRankTechniques();

      if (userId !== 'new') {
        getUserDetails(userId, 'student');
        // getContacts()
      }
      setLoading(false);

      // Set initial Meter setting
      setMeterValue(100 / questions.length);
    })();
  }, [userId]);

  useEffect(() => {
    if (incidentValues.studentName.firstName) {
      getContacts();
      getAndRankTechniques();
      getIncidentPlans(userId);
    }
  }, [incidentValues.studentName]);

  useEffect(() => {
    setIncidentValues(incidentValues => ({
      ...incidentValues,
      ...responses,
      studentName: userDetails,
      staffInvolved: responses?.staffInvolved
        ? JSON.parse(responses?.staffInvolved)
        : [],
      studentsInvolved: responses?.studentsInvolved
        ? JSON.parse(responses?.studentsInvolved)
        : [],
    }));

    if (responses?.interventions) {
      setIntervention(JSON.parse(responses.interventions));
    }
  }, [responses]);

  return (
    <Box width="xlarge">
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">BehaviourSmart</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        {userId === 'new' ? 'New ' : ''}Incident Report
        {userId !== 'new'
          ? ': ' + userDetails.firstName + ' ' + userDetails.lastName
          : ''}
      </BreadcrumbBar>

      {/* <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
        <Box direction='column' pad={{ horizontal: 'medium' }}>
          <Text margin={{ top: 'small' }} size='xlarge'>{userId === 'new' ? 'New ' : ''}Incident Report{userId !== 'new' ? ': ' + userDetails.firstName + ' ' + userDetails.lastName : ''}</Text>
          <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
        </Box>
      </Box> */}
      <Box
        gridArea="main"
        background="white"
        direction="column"
        gap="small"
        round="small"
        flex="grow"
      >
        <Box
          gap="small"
          margin={{ horizontal: 'small' }}
          pad="small"
          round="small"
        >
          <Meter
            background="light-2"
            size="full"
            thickness="xsmall"
            type="bar"
            values={[{ color: 'brand', value: meterValue }]}
          />
          {studentValues ? (
            <Tabs
              activeIndex={tabIndex}
              content={tabContent}
              onActive={onActiveTab}
              full
            />
          ) : (
            <Box align="center">
              <PulseLoader size={15} color="orange" loading={loading} />
            </Box>
          )}

          {/* <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />
          <Box direction='row' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => previousPage()} secondary />
            {tabIndex < tabContent.length - 1 &&
              <Button icon={<FontAwesomeIcon icon={['fal', 'save']} color='#fff' />} label='Save for later' onClick={() => submitForm(true)} />}
            <Button label={tabIndex !== tabContent.length - 1 ? 'Next Page' : 'Submit'} onClick={() => nextPage()} primary />
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}

export default IncidentEdit;
